import { css } from 'styled-components';
import { media } from './mediaQuery';

export const type = (type) => {

  const types = {
    heading: css`
      font-weight: normal;
      font-size: 60px;
      line-height: 65px;
      letter-spacing: 0.020em;

      ${css`${media.xlarge`
        font-size: 80px;
        line-height: 85px;
        letter-spacing: 0.030em;
      `}`}
    `,
    large: css`
      font-weight: normal;
      font-size: 35px;
      line-height: 40px;
      letter-spacing: 0.020em;

      ${css`${media.xlarge`
        font-size: 50px;
        line-height: 55px;
        letter-spacing: 0.030em;
      `}`}
    `,
    medium: css`
      font-weight: normal;
      font-size: 17px;
      letter-spacing: 0.020em;
      line-height: 21px;

      ${css`${media.xlarge`
        font-size: 24px;
        letter-spacing: 0.030em;
        line-height: 30px;
      `}`}
    `,
    body: css`
      font-weight: normal;
      font-size: 15px;
      letter-spacing: 0.050em;
      line-height: 18px;

      ${css`${media.xlarge`
        font-size: 18px;
        letter-spacing: 0.050em;
        line-height: 22px;
      `}`}
    `,

    caption: css`
      font-weight: normal;
      font-size: 12px;
      letter-spacing: 0.050em;
      line-height: 14px;
    `,
    'm-heading': css`
      font-weight: normal;
      font-size: 35px;
      line-height: 38px;
      letter-spacing: 0.030em;
    `,
    'm-large': css`
      font-weight: normal;
      font-size: 25px;
      line-height: 30px;
      letter-spacing: 0.030em;
    `,
    'm-body': css`
      font-weight: normal;
      font-size: 15px;
      letter-spacing: 0.030em;
      line-height: 18px;
    `,
  }

  return types[type];
}