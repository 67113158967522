import React from 'react';
import styled from 'styled-components';
import { EmailFooterFormSmall } from '../EmailFooterForm/EmailFooterForm';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';

const Wrapper = styled.div` 
  display: grid;
  grid-template-columns: repeat(12,1fr);
`

const Statement = styled.h2`
  ${fontstack.default}
  ${type('m-heading')}
  font-weight: 300;
  color: #383B23;
  grid-column: 1 / span 12;
  margin: 0 0 30px;

  ${media.large`
    ${type('heading')}
    font-weight: 300;
    margin: 0 0 60px;
  `}
`

const FieldWrapper = styled.div` 
  grid-column: 1 / span 12;

  ${media.large`
    grid-column: 1 / span 6;
  `}
`

const NewsletterForm = (props) => {
  const { statement } = props;
  return (
    <Wrapper>
    <Statement>{statement}</Statement>
    <FieldWrapper>
      <EmailFooterFormSmall/>
    </FieldWrapper>
    </Wrapper>
  )
}

export default NewsletterForm;