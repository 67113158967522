import React from 'react';
import { v4 as uuidv4 }from "uuid";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { getStyleObjectFromString } from './HtmlParserHelper';
import { stripHtml } from "string-strip-html";
//import InlineAnchor from "../InlineAnchor/InlineAnchor";
import { Paragraph, Strong, Em, Anchor, H3, OrderedList, UnorderedList, LI, Sup } from "./HtmlParserTags";

const HtmlParserTransform = function(node, index) {
    const HtmlParserConvertAnchor = function(node) {
      const children = node.children.map((child) => HtmlParserTransform(child));
      return <Anchor key={uuidv4()} {...node.attribs}>{children}</Anchor>;
    }

    const HtmlParserConvertParagraph = function(node) {
        const children = node.children.map((child) => HtmlParserTransform(child));
        return <Paragraph key={uuidv4()}>{children}</Paragraph>;
    
    }

    const HtmlParserConvertH3 = function(node) {
      const children = node.children.map((child) => HtmlParserTransform(child));
      return <H3 key={uuidv4()}>{children}</H3>;
    }

    const HtmlParserConvertLI = function(node) {
      const children = node.children.map((child) => HtmlParserTransform(child));
      return <LI key={uuidv4()}>{children}</LI>;
    }

    const HtmlParserConvertOrderedList = function(node) {
      const children = node.children.map((child) => HtmlParserTransform(child));
      return <OrderedList key={uuidv4()}>{children}</OrderedList>;
    }

    const HtmlParserConvertUnorderedList = function(node) {
      const children = node.children.map((child) => HtmlParserTransform(child));
      return <UnorderedList key={uuidv4()}>{children}</UnorderedList>;
    }
    
    const HtmlParserConvertStrong = function(node) {
        const children = node.children.map((child) => HtmlParserTransform(child));
        return <Strong key={uuidv4()}>{children}</Strong>;
    
    }

    const HtmlParserConvertEm = function(node) {
        const children = node.children.map((child) => HtmlParserTransform(child));
        return <Em key={uuidv4()}>{children}</Em>;
    
    }

    const HtmlParserConvertSup = function(node) {
      const children = node.children.map((child) => HtmlParserTransform(child));
      return <Sup key={uuidv4()}>{children}</Sup>;
  
  }

    const HtmlParserConvertSpan = function(node) {
        const { style, ...attribs} = node.attribs;
        const children = node.children.map((child) => HtmlParserTransform(child));
        let styleObject = {};

        if(style) {
            styleObject = getStyleObjectFromString(style);
        }
        return <span key={uuidv4()} style={styleObject} {...attribs}>{children}</span>;
    }

    const HtmlParserConvertBreak = function(node) {
      return <br/>;
    }
    
    const parseObject = {
        "a": HtmlParserConvertAnchor,
        "p": HtmlParserConvertParagraph,
        "strong": HtmlParserConvertStrong,
        "em": HtmlParserConvertEm,
        "span": HtmlParserConvertSpan,
        "h3": HtmlParserConvertH3,
        "ol": HtmlParserConvertOrderedList,
        "ul": HtmlParserConvertUnorderedList,
        "li": HtmlParserConvertLI,
        "br": HtmlParserConvertBreak,
        "sup": HtmlParserConvertSup
    }

    
    if(node.type === "tag") {
        var content = parseObject[node.name];

        if(content) {
            return content(node);
        }
    } else {
        return convertNodeToElement(node, index, HtmlParserTransform);
    }

}



const HtmlParser = props => {
    const { children, strip } = props;
    const content = strip && strip.length > 0 ? stripHtml(children, {
      onlyStripTags: strip,
    }).result : children;

    const text = ReactHtmlParser(content, {
        transform: HtmlParserTransform
    });

    return (
        <React.Fragment>
            {text}
        </React.Fragment>
    )
}

export default HtmlParser;