import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { NewsTicker as ManualTicker } from '@manualengineering/react-news-ticker';
import CSSNewsTicker from '../NewsTicker/CSSNewsTicker';
import Tag from '../Tag/Tag';
import { StoreContext } from '../../context/store-context';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';
import CloseSvg from './assets/close.svg';

const Nav = styled.nav` 
  background: #383B23;
  border: none;
  outline: none;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: 2;
  position: relative;
  overflow: hidden;

  ${media.large`
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 60px;
  `}

`

const CloseIcon = styled(CloseSvg)`
  position: absolute;
  right: 20px;
  stroke: #f3efd7;
  cursor: pointer;
  z-index: 10;
  display: none;

  ${media.large`
    right: 30px;
    display: block;
  `}
`

const EmptyMessage = styled.p` 
  ${fontstack.default}
  ${type('m-body')}
  color: #F2EADE;
  margin: 0;
  ${media.large`
    ${type('body')}
  `}
`

const TickerText = styled.div` 
  ${fontstack.default}
  ${type('m-body')}
  color: #F2EADE;
  margin: 0;
  position: relative;
  white-space: pre;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  ${media.large`
    ${type('body')}
    margin: 0 50px;
  `}
`

const MobileBlocker = styled.div` 
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  ${media.large`
    display: none;
  `}
`

const CustomLink = styled(Link)` 
  padding: 0 0 2.5px;
  border-bottom: 1px solid #F2EADE; 
`

const BarTag = styled(Tag)`
  margin: 0 10px;
`

const CartBar = (props) => {
  const { minimum, domestic } = props;
  const { checkout, toggleCart } = React.useContext(StoreContext)

  const subtotalPrice = checkout.lineItemsSubtotalPrice ? checkout.lineItemsSubtotalPrice.amount : 0;
  const minAmount = minimum && minimum.prerequisiteSubtotalRange.greaterThanOrEqualTo ? minimum.prerequisiteSubtotalRange.greaterThanOrEqualTo : null;
  const toMinimumAmount = minAmount && Number(minAmount) > Number(subtotalPrice) ?  minAmount - subtotalPrice : null; 
  const emptyCart = checkout.lineItems.length === 0
  const isFreeShipping = domestic ? Number(subtotalPrice) >= domestic.price_based_shipping_rates[0].min_order_subtotal ? 'free delivery' : false : false;
  const toFreeShipping = domestic && Number(domestic.price_based_shipping_rates[0].min_order_subtotal) > Number(subtotalPrice) ?  Number(domestic.price_based_shipping_rates[0].min_order_subtotal) - Number(subtotalPrice) : null;
  const percentageOff = minimum && minimum.valueV2.percentage ? `${minimum.valueV2.percentage * -1}% discount`: null;

  const isThreshold = Number(subtotalPrice) >= 300  ? true : false;
  const toThreshold = 300 > Number(subtotalPrice)  ?  300 - Number(subtotalPrice) : null;

  const discountCode = checkout.discountApplications.length > 0 && checkout.discountApplications[0].applicable ? checkout.discountApplications[0] : false;
  const discountLabel = discountCode ? discountCode.value.amount ? `${discountCode.value.currencyCode}$${parseInt(discountCode.value.amount)} discount` : discountCode.value.percentage ? `${discountCode.value.percentage}% discount`: null : null;
  const FreeShippingTag = () => {
    return (
      <BarTag theme='light'>HK${toFreeShipping}</BarTag>
    )
  }

  const PickUpDeliveryThresholdTag = () => {
    return (
      <BarTag theme='light'>HK${toThreshold}</BarTag>
    )
  }

  const ReachedTagArray = () => {
    const tagArray = [discountLabel,isFreeShipping];

    const msgArray = [];
    tagArray.forEach((tag,index) => {
      if(tag) {
         if(msgArray.length === 0) {
           msgArray.push(<BarTag theme='light'>{tag}</BarTag>)
         } else {
            msgArray.push(<> + <BarTag theme='light'>{tag}</BarTag></>)
         }
      }
    });

    return (
      <>
        { msgArray }
      </>
    )
  }
  return (
    <Nav>
      { emptyCart ? <EmptyMessage>No goodies yet. <CustomLink to="/products" onClick={toggleCart}>Start nourishing.</CustomLink></EmptyMessage> : !isThreshold ? <>
        <MobileBlocker/>
        <CSSNewsTicker speed={'60s'}>
          { new Array(10).fill().map(() => {
            return <TickerText>You are <PickUpDeliveryThresholdTag/> away from being eligible for pickup or delivery.</TickerText>
          })}
        </CSSNewsTicker></> : !isFreeShipping && !discountCode ?
        <>
        <MobileBlocker/>
        <CSSNewsTicker speed={'60s'}>
          { new Array(10).fill().map(() => {
            return <TickerText>You are <FreeShippingTag/> away from free delivery.</TickerText>
          })}
        </CSSNewsTicker></> : <>
        <MobileBlocker/><CSSNewsTicker speed={'60s'}>
          { new Array(10).fill().map(() => {
            return <TickerText>Congratulations—you’ve qualified for <ReachedTagArray/></TickerText>
          })}
          
        </CSSNewsTicker></>
      }
      {/*<CloseIcon onClick={toggleCart}/>*/}
    </Nav>
  )
}

export default CartBar;