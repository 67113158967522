import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Section from '../Section/Section';
import NewsletterForm from '../Newsletter/NewsletterForm';
import { NuteSmile } from '../NuteBrand/NuteBrand';
import SideMenuTertiaryItem from '../SideMenu/SideMenuTertiaryItem';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';
import { linkhover } from '../../utils/lineHover';
import { StoreContext } from '../../context/store-context';

const Wrapper = styled.footer` 
  position: relative;
  width: 100%;
  z-index: 0;
  min-height: 100vh;
  height: 100%;
  background: #D8C7AE;
  overflow: hidden;
  box-sizing: border-box;
  padding: 40px 0 20px;
  display: flex;

  ${media.large`
    height: 100vh;
    padding: 100px 0 20px;
  `}
`

const MainWrapper = styled(Section)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`

const TopWrapper = styled.div` 
  display: grid;
  grid-template-columns: repeat(12,1fr);
`

const BottomWrapper = styled.div`  
  margin: 150px 0 0;

  ${media.large`
    display: grid;
    grid-template-columns: repeat(12,1fr);
    margin: 0;
  `}
`

const MenuGroup = styled.div`
  margin: 0 0 ${props => !props.none ? '20px' : '0'};
  width: 100%;
  display: flex;
  flex-direction: column;

  ${media.large`
    display: grid;
    grid-template-columns: repeat(1,1fr);
    margin: 0 0 ${props => !props.none ? '40px' : '0'};
  `}
  
`

const MenuHead = styled(SideMenuTertiaryItem)` 
  color: #383B23;
  margin: 0 0 20px;
  opacity: 0.5;
  text-transform: uppercase;
`

const MenuHeadSpacer = styled.div`
  margin: 0 0 20px;
  height: 18px;
  width: 100%;
  position: relative;

`

const MenuItemWrapper = styled.div`
  position: relative;
`

const MenuItem = styled(SideMenuTertiaryItem)` 
  color: #383B23;
  margin: 0 0 5px;
  ${linkhover('#383B23')}
`

const LeftWrapper = styled.div`
  grid-column: 1 / span 12;
  position: relative;

  ${media.large`
    grid-column: 1 / span 8;
    margin: 0;
  `}
`

const RightWrapper = styled.div`
  grid-column: 1 / span 12;
  position: relative;

  ${media.large`
    grid-column: 10 / span 3;
  `}
  
`

const MenuInner = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  margin: 40px 0 0;

  ${media.large`
    display: block;
    margin: 0;
  `}
`

const CopyRightWrapper = styled.div` 
  display: flex;
  align-items: flex-end;
  height: 100%;
`

const CopyRight = styled.div` 
  ${fontstack.default}
  ${type('m-body')}
  color: #383B23;

  ${media.large`
    ${type('body')}
  `}
`

const SmileWrapper = styled.div`
  position: absolute;
  top: 70%;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: -1;

  ${media.large`
    top: 350px;
  `}
`

const SmileInner = styled.div` 
  position: relative;
  width: 95%;

  ${media.large`
    width: 45%;
  `}
`

const eyeBounce = keyframes`
  0%, 10% {
    transform: translate(-470.475px,11949.637px);
  }
  20% {
    transform: translate(-470.475px, 11970px);
  }
  40% {
    transform: translate(-470.475px, 11935px);
  }
  60% {
    transform: translate(-470.475px, 11960px);
  }
  80% {
    transform: translate(-470.475px, 11945px);
  }
  100% {
    transform: translate(-470.475px, 11949.637px);
  }
`

const eyeBounce2 = keyframes`
  0%, 10% {
    transform: translate(-156.514px,11949.637px);
  }
  20% {
    transform: translate(-156.514px, 11970px);
  }
  40% {
    transform: translate(-156.514px, 11935px);
  }
  60% {
    transform: translate(-156.514px, 11960px);
  }
  80% {
    transform: translate(-156.514px, 11945px);
  }
  100% {
    transform: translate(-156.514px, 11949.637px);
  }
`

const eyeOpacity = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
`



const AnimatedSmile = styled.div` 
  transform: translateY(var(--footer-progress)) rotate(var(--footer-rotation));

  & svg { 
    overflow:visible;
  }

  & #Path_14 {
    display: var(--footer-eye-one);

    ${({isActive}) => isActive && css`
      animation: ${eyeBounce} 2s forwards ease;
    `}
    
  }

  & #Path_15 {
    display: var(--footer-eye-two);

    ${({isActive}) => isActive && css`
      animation: ${eyeBounce2} 2s forwards ease 0.2s;
    `}
  }
`

const FooterFull = () => {

  const {
    allShopifyCollection: { edges },
    prismicNavigation: { data }
  } = useStaticQuery(graphql`
    query {
      allShopifyCollection(sort: {order: DESC, fields: handle}) {
        edges {
          node {
            title
            handle
            productsCount
            products {
              id
              title
              ...ProductCard
              metafields {
                key
                value
              }
            }
          }
        }
      }
      prismicNavigation {
        data {
          body {
            ... on PrismicNavigationDataBodyCollectionItem {
              id
              primary {
                handle
                title
              }
              items {
                product {
                  title
                  handle
                  product_type
                }
                tag_label
              }
            }
          }
        }
      }
    }
  `);

  const { width, footerTrigger } = React.useContext(StoreContext);
  const navigationItems = data.body;
  return (
    <Wrapper>
      <MainWrapper none>
        <TopWrapper>
          <LeftWrapper>
            <NewsletterForm statement='Short reads about all things nutrition, health, Earth, and us.'/>
          </LeftWrapper>
          <RightWrapper>
            <MenuInner>
              <MenuGroup>
                <MenuHead>shop</MenuHead>
                { navigationItems ? navigationItems.map((item,i) => {
                  const collection = item;
                  return <MenuItemWrapper key={i}><MenuItem to={`/products/${collection.primary.handle}`}>{collection.primary.title}</MenuItem></MenuItemWrapper>
                }) : null }
              </MenuGroup>
              <MenuGroup>
                <MenuHead>digest</MenuHead>
                <MenuItemWrapper><MenuItem to='/we'>We</MenuItem></MenuItemWrapper>
                <MenuItemWrapper><MenuItem to='/partner'>Partner</MenuItem></MenuItemWrapper>
                <MenuItemWrapper><MenuItem to='/faq'>FAQs</MenuItem></MenuItemWrapper>
              </MenuGroup>
              <MenuGroup>
                <MenuHead>Connect</MenuHead>
                <MenuItemWrapper><MenuItem target="__blank" href="https://wa.me/85260362938">Chat with us</MenuItem></MenuItemWrapper>
                <MenuItemWrapper><MenuItem href="mailto:hello@nutefoods.com">Email</MenuItem></MenuItemWrapper>
                <MenuItemWrapper>{ width < 1080 ? <MenuItem href="instagram://user?username=nutefoods">Instagram</MenuItem> : <MenuItem target="__blank" href="http://instagram.com/nutefoods/">Instagram</MenuItem> }</MenuItemWrapper>
              </MenuGroup>
              { width < 1080 ?  <MenuGroup none>
                <MenuHeadSpacer />
                <MenuItemWrapper><MenuItem to='/terms'>Terms of Service</MenuItem></MenuItemWrapper>
                <MenuItemWrapper><MenuItem to='/privacy-policy'>Privacy Policy</MenuItem></MenuItemWrapper>
              </MenuGroup> : null }
            </MenuInner>
          </RightWrapper>
        </TopWrapper>
        <BottomWrapper>
          <LeftWrapper>
            <CopyRightWrapper>
              <CopyRight>©{ new Date().getFullYear() }—nüte limited. All Rights Reserved.</CopyRight>
            </CopyRightWrapper>
          </LeftWrapper>
          { width >= 1080 ? <RightWrapper>
              <MenuGroup none>
              <MenuItemWrapper><MenuItem to='/terms'>Terms of Service</MenuItem></MenuItemWrapper>
              <MenuItemWrapper><MenuItem to='/privacy-policy'>Privacy Policy</MenuItem></MenuItemWrapper>
              </MenuGroup>
          </RightWrapper> : null }
        </BottomWrapper>
      </MainWrapper>
      <SmileWrapper>
        <SmileInner>
          <AnimatedSmile isActive={footerTrigger}>
            <NuteSmile />
          </AnimatedSmile>
        </SmileInner>
      </SmileWrapper>
    </Wrapper>
  )

}

export default FooterFull;