import React from 'react';
import styled from 'styled-components'
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';
import Icon from './assets/arrow.svg';
import IconDiagonal from './assets/diagonal.svg';

const Wrapper = styled.a`
  display: inline-flex;
  ${fontstack.default}
  color: ${props => props.theme === 'light' ? '#F2EADE': '#383B23'};
  align-items: center;
  border-style: solid;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom-width: ${props => props.active ? '1px': '0'};
  border-color: ${props => props.theme === 'light' ? '#F2EADE': '#383B23'};
  flex-direction: ${props => props.reversed ? 'row-reverse' : 'row'};
`

const Arrow = styled(Icon)`
  margin: ${props => props.reversed ? ' 0 10px 0 0' : ' 0 0 0 10px'};
  stroke: ${props => props.theme === 'light' ? '#F2EADE': '#383B23'};
  transform: ${props => props.reversed ? 'rotate(180deg)' : 'rotate(0deg)'};

  ${media.large`
    margin: ${props => props.reversed ? ' 0 15px 0 0' : ' 0 0 0 15px'};
  `}
`

const ArrowDiagonal = styled(IconDiagonal)`
  margin: 0 0 0 15px;
  stroke: ${props => props.theme === 'light' ? '#F2EADE': '#383B23'};
  width: 20px;
  ${media.large`
    width: 47px;
  `}
`

const Default = styled(Wrapper)` 
  ${type('medium')}
  font-weight: 300;
`

const Small = styled(Wrapper)` 
  ${type('m-body')}

  ${media.large`
    ${type('body')}
  `}
`

const ArrowLink = (props) => {
  const { children, theme, ...rest } = props;
  return (
    <Default theme={theme} {...rest}>
      {children}
      <Arrow theme={theme}/>
    </Default>
  )
}

export { ArrowLink };


const ArrowLinkSmall = (props) => {
  const { children, theme, active, ...rest } = props;
  return (
    <Small theme={theme} active={active} {...rest}>
      {children}
      <Arrow theme={theme} {...rest}/>
    </Small>
  )
}

export { ArrowLinkSmall };


const Large = styled(Wrapper)` 
  ${type('m-large')}
  font-weight: 300;

  ${media.large`
    ${type('large')}
    font-weight: 300;
  `}
`

const LargeInner = styled.div` 
  border-style: solid;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom-width: ${props => props.active ? '1px': '0'};
  border-color: ${props => props.theme === 'light' ? '#F2EADE': '#383B23'};
`

const ArrowLinkLargeDiagonal = (props) => {
  const { children, theme, active, ...rest } = props;
  return (
    <Large theme={theme} {...rest}>
      <LargeInner theme={theme} active={active}>
        {children}
      </LargeInner>
      <ArrowDiagonal theme={theme}/>
    </Large>
  )
}

export { ArrowLinkLargeDiagonal };