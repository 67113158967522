import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from "swiper/react";
import { graphql, useStaticQuery, Link } from 'gatsby';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';
import { StoreContext } from '../../context/store-context';
// Import Swiper styles
import "swiper/css";

const Nav = styled.nav` 
  background: #383B23;
  border: none;
  outline: none;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: 1;
  position: relative;

  ${media.large`
    padding: 0 30px;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    height: 80px;
  `}

`

const NavItem = styled(Link)`
  ${fontstack.default}
  ${type('caption')}
  color: #F2EADE;
  opacity: ${props => props.active ? '1' : '0.4'};
  transition: opacity .2s cubic-bezier(0.215, 0.61, 0.355, 1);

  ${media.large`
    ${type('body')}
    margin: 0 15px;
  `}

  &:hover {
    opacity: 1;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`

const PlpNavigation = ({active}) => {

  const {
    allShopifyCollection: { collectionNames },
    prismicNavigation: { data }
  } = useStaticQuery(graphql`
    query {
      allShopifyCollection(sort: {order: DESC, fields: handle}) {
        collectionNames: nodes {
          title
          handle
          productsCount
        }
      }
      prismicNavigation {
        data {
          body {
            ... on PrismicNavigationDataBodyCollectionItem {
              id
              primary {
                handle
                title
              }
              items {
                product {
                  title
                  handle
                  product_type
                }
                tag_label
              }
            }
          }
        }
      }
    }
  `)
  const { width } = React.useContext(StoreContext);
  const navigationItems = data.body;

  const desktopLayout = () => {
    return (
      <>
        <NavItem to='/products' active={'/products' === active ? true : false}>Shop All</NavItem>
        { navigationItems ? navigationItems.map((collection,i) => {
          return <NavItem key={i} active={collection.primary.handle === active ? true : false} to={`/products/${collection.primary.handle}`}>{collection.primary.title}<sup>{collection.items.length}</sup></NavItem>;
        }) : null }
      </>
    )
  }

  const mobileLayout = () => {

    const indexes = [];
    indexes.push('/products');
    collectionNames.forEach((collection) => { 
      indexes.push(`${collection.handle}`)
    })
    indexes.push('/create-your-own');
    return (<Swiper
        slidesPerView={"auto"}
        spaceBetween={15}
        onSwiper={(swiper) => swiper.slideTo(indexes.indexOf(active), 0)}
        className="mySwiper"
        style={{marginLeft:'0',marginRight:'0',padding: '0 20px'}}
      >
        <SwiperSlide style={{width: 'auto'}}><NavItem to='/products' active={'/products' === active ? true : false}>Shop All</NavItem></SwiperSlide>
        { navigationItems ? navigationItems.map((collection) => {
          return <SwiperSlide style={{width: 'auto'}}><NavItem active={collection.primary.handle === active ? true : false} to={`/products/${collection.primary.handle}`}>{collection.primary.title}<sup>{collection.items.length}</sup></NavItem></SwiperSlide>;
        }) : null }
      </Swiper>)
  }
  

  return (
    <Nav>
      { width >= 1080 ? desktopLayout() : mobileLayout() }
    </Nav>
  )
}

export default PlpNavigation;