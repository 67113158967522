import React from 'react';
import styled from 'styled-components';
import Logo from './assets/logo.svg';
import Smile from './assets/graphic.svg';

const NuteLogo = styled(Logo)`
  fill: ${props => props.theme === 'light' ? '#F3EFD7' : '#383B23'};
`


const NuteBrand = (props) => {
  const { theme, ...rest } = props;
  return (
    <NuteLogo theme={theme} {...rest}/>
  )
}

export default NuteBrand;

const NuteSmile = () => {

  return (
    <Smile />
  )
}

export { NuteSmile };