import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../Modal/Modal';
import CallToAction from '../CallToAction/CallToAction';
import { useForm } from 'react-hook-form';
import { navigate } from 'gatsby';
import { StoreContext } from "../../context/store-context";
import { TextField, Errors } from '../Forms/Forms';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';

const Wrapper = styled(Modal)`
  max-width: 600px;
  width: 100%;
`

const Create = styled(CallToAction)`
  max-width: intial;
  width: 100%;

  ${media.large`
    max-width: 284px;
    width: 100%;
  `}
`

const FormWrapper = styled.form` 
  position: relative;
`

const SubmitButton = styled.button`
  outline: none;
  border: none;
  background: transparent;
  width: 100%;
`

const BottomWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0 0;
`

const Label = styled.p`
  margin: 0;
  ${fontstack.default}
  ${type('caption')}
  color: #383B23;

  ${media.large`
    ${type('body')}
  `}
`

const Link = styled.a`
  ${fontstack.default}
  ${type('caption')}
  color: #383B23;
  margin: 0 20px;
  cursor: pointer;

  ${media.large`
    ${type('body')}
  `}
`

const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0 0;

  ${media.large`
    margin: 50px 0 35px;
  `}
`

const AccountCreate = ({state, callback}) => {
  const { toggleLogin, loginOpen, login, customerCreate } = React.useContext(StoreContext);
  const { register, handleSubmit } = useForm();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ errors, setErrors ] = useState();
  const [ hideForm, setHideForm ] = useState(false);

  const onSubmit = (data) => {
    setErrors(false);
    setIsLoading(true);
    
    customerCreate(data).then((response)=>{
      setIsLoading(false);
      
      const customerCreate = response.data.customerCreate;
      const errors = response.errors;
      if(customerCreate && customerCreate.customerUserErrors) {
        setErrors(customerCreate.customerUserErrors);
        return;
      } else if (errors) {
        setErrors(errors);
        return;
      }

      if(customerCreate && customerCreate.customer) {
        // account activated automatically so let's sign them in
        return login(data)
      } else {
        // need to activate first.
        setIsLoading(false);
        setHideForm(true);
        setErrors([{
          message: `We have sent an email to ${data.email}, please click the link included to verify your email address.`
        }]);


        return null;
      }
      
    }).then(response => {
      if(response) {
        if(response.customerAccessToken) {
          //success
          toggleLogin();
          navigate("/account")
        } else {
          setIsLoading(false);
          if(response.customerUserErrors) {
            setErrors(response.customerUserErrors);
          }
        }
      }
      
    })
  }

  return (
    <Wrapper title='New Account' state={state} statement='Hello,
    it’s nüte to see you' onClose={()=>toggleLogin()}>
      { errors ? <Errors errors={errors} /> : null }
      { !hideForm ? <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <TextField type="text" placeholder="First Name" withBorder {...register("firstName")}/>
        <TextField type="text" placeholder="Last Name" withBorder {...register("lastName")}/>
        <TextField type="text" placeholder="Email" withBorder {...register("email", {required: true, pattern: /^\S+@\S+$/i})}/>
        <TextField type="password" placeholder="Password" withBorder {...register("password")}/>
        <BottomWrapper>
        <SubmitButton><Create isLoading={isLoading}>Create Account</Create></SubmitButton>
          <LinkWrapper>
            <Label>Already have an account?</Label>
            <Link onClick={()=>callback(null)}>Sign In</Link>
          </LinkWrapper>
        </BottomWrapper>
      </FormWrapper> : null}
    </Wrapper>
  )
}

export default AccountCreate;