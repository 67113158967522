import React from 'react';
import styled from 'styled-components';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';

const Wrapper = styled.div`
  position: relative;
  width: 100%;

  &:before {
      display: block;
      content: '';
      height: ${props => props.noborder ? '0px' : '1px' };
      width: 100%;
      background: ${props => props.theme === 'light' ? '#F2EADE': '#383B23'};
    }
`

const Label = styled.div`
  ${fontstack.default}
  color: ${props => props.theme === 'light' ? '#F2EADE': '#383B23'};
  ${type('m-body')}
  display: flex;
  align-items: center;
  margin: 30px 0 0;

  ${media.large`
    ${type('body')}
  `}

  &:before {
    display: block;
    content: '';
    height: 10px;
    width: 10px;
    background: ${props => props.theme === 'light' ? '#F2EADE': '#383B23'};
    margin: 0 10px 0 0;
    border-radius: 50%;
  }
`

const ModuleDivider = (props) => {
  const { children, theme, noborder} = props;


  return (
    <Wrapper noborder={noborder} theme={theme}>
      <Label theme={theme}>{children}</Label>
    </Wrapper>
  )

}

export default ModuleDivider;