import React, { useState, useEffect, useRef } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./assets/date-picker.css";
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';

const CartDateWrapper = styled.div`
  position: relative;
  ${fontstack.default}
  ${type('m-body')}
  display: flex;
  flex-direction: column-reverse;
  margin: 0 0 20px;

  ${media.large`
    ${type('body')}
    flex-direction: row;
  `}
`

const CartDateMain = styled.div` 
  display: flex;
  justify-content: center;
  
  ${media.large`
    display: inline-block;
    border-right: 1px solid #383B2340;
    padding-right: 50px;
    margin-right: 30px;
  `}
`

const CartDateSide = styled.div` 
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${media.large`
    ${type('body')}
    flex-direction: row;
    justify-content: flex-start;
  `}
`

const CartDateButtons = styled.div`
  display: flex;
`
const CartDate = (props) => {
  const { ...rest } = props;

  const MyContainer = ({ className, children }) => {
    const filteredChildren = [];

    const buttons = React.Children.map(children, child => {
      if(React.isValidElement(child) && child.type === 'button') {
        return React.cloneElement(child);
      } else {
        filteredChildren.push(child);
      }
    });

    const monthChildren = React.Children.toArray(children).filter((child, i) => child.props.className === 'react-datepicker__month-container');
    const monthComponent = React.cloneElement(monthChildren[0].props.children[0].props.children[0], { className: ''});

    return (
      <CartDateWrapper>
        <CartDateMain>{monthChildren}</CartDateMain>
        <CartDateSide>
          { monthComponent }
          <CartDateButtons>
            {buttons}
          </CartDateButtons>
          </CartDateSide>
      </CartDateWrapper>
    );
  };

  return (
    <>
    <DatePicker calendarContainer={MyContainer} formatWeekDay={nameOfDay => nameOfDay.substr(0,1)} inline fixedHeight {...rest}/>
    </>
    
  )
}

export { CartDate }
