import React from 'react';
import styled from 'styled-components';
import Overlay from '../Overlay/Overlay';
import SmoothieConcept from './SmoothieConcept';
import { Transition } from 'react-transition-group';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { StoreContext } from '../../context/store-context';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';


const LearnMoreWrapper = styled.div`
  position: relative;
  margin: 30px 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`

const LearnMore = styled.div`
${fontstack.default}
  ${type('body')}
  font-weight: 400;
  color: #383B23;
  text-decoration: underline;
  cursor: pointer;
  grid-column: 1 / span 2;

  ${media.large`
    grid-column: 2;
  `}
`

export const GranolaConceptOverlay = (props) => {
  const { conceptGranolaOpen, setConceptGranolaOpen } = React.useContext(StoreContext);

  const {
    prismicConcept: { data }
  } = useStaticQuery(graphql`
    query {
      prismicConcept(data: {title_header: {eq: "Functional Granola Concept"}}) {
        id
        data {
          main_copy
          title_header
          sub_copy
          method {
            title {
              richText
              html
            }
            image {
              alt
              url(imgixParams: {q: 80, width: 750})
            }
            video {
              url
            }
            content {
              richText
              html
            }
          }
        }
      }
    }
  `);

  return (
    <Transition in={conceptGranolaOpen} timeout={{
      enter: 0,
      exit: 200,
     }}>
      {state => { 
         if(state !== 'exited') {
          return (<Overlay state={state} onClose={() => setConceptGranolaOpen(false)} {...props}>
            <SmoothieConcept data={data} noMargin/>
          </Overlay>)
         }
       }}
     </Transition>
  )
}

export const GranolaConceptOpen = (props) => {
  const { setConceptGranolaOpen } = React.useContext(StoreContext);
  return (
    <LearnMoreWrapper>
      <LearnMore onClick={()=> { setConceptGranolaOpen(true) }}>
        How to enjoy
      </LearnMore>
    </LearnMoreWrapper>
  )
}