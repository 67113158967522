import * as React from "react"
import styled from 'styled-components';
import { SkipNavContent, SkipNavLink } from "../skip-nav";
import { createGlobalStyle } from 'styled-components';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/all';
import { StoreContext } from "../../context/store-context";
import SideMenu from "../SideMenu/SideMenu";
import Cart from "../Cart/Cart";
import Account from "../Account/Account";
import EmailPrompt from "../EmailPrompt/EmailPrompt";
import Header from "./Header";
import Footer from './Footer';
import Main from './Main';
import { SmoothieConceptOverlay } from "../SmoothieConcept/SmoothieConcept";
import { GranolaConceptOverlay } from "../SmoothieConcept/GranolaConceptOverlay";
import { Seo } from "../seo"
import { media } from "../../utils/mediaQuery";
import '../../fonts/fonts.css';

gsap.registerPlugin(ScrollTrigger);
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background: #D8C7AE;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  }

  :root {
    --footer-progress: 0;
    --footer-rotation: 0;
    --footer-eyeone: block;
    --footer-eyetwo: block;
  }
`

const FooterWrapper = styled.div`
  z-index: 0;
  position: relative;

  ${media.large`
    top: 0;
    position: absolute;
  `}
`

export function Layout({ children, plp, navBorder, excludeFooter, squared, background }) {
  const [ viewport, setViewport ] = React.useState();
  const [ footerEl, setFooterEl ] = React.useState();
  const Controller = React.useRef();
  const timer = React.useRef();
  const { width, setFooterTrigger } = React.useContext(StoreContext);
  
  
  React.useLayoutEffect(() => {
    const root = document.documentElement;

    if(width > 0 && viewport && footerEl) {
      if(!excludeFooter && width >= 1080) {
        
        if(Controller.current) {
          Controller.current.kill();
        }
  
        if(timer.current) {
          clearTimeout(timer.current);
        }
  
        timer.current = setTimeout(() => {
          
          Controller.current = ScrollTrigger.create({
            trigger: viewport,
            endTrigger: viewport,
            start: "top top",
            end: "bottom top",
            markers: false,
            pin: footerEl,
            onEnterBack: (self) => {
              setFooterTrigger(false);
            },
            onLeave: (self) => {
              setFooterTrigger(true);
            },
            onUpdate: (self) => {
              const progress = (self.progress - 0.8) / 0.2;
              const value = progress > 0 ? progress : 0;
              const rotateValue = 20 - value * 20;
  
  
              const eyeOne = rotateValue < 5 ? 'block' : 'none';
              const eyeTwo = rotateValue < 2 ? 'block' : 'none';
  
              root.style.setProperty('--footer-progress',`${100 - (value*100)}%`);
              root.style.setProperty('--footer-rotation',`${rotateValue}deg`);
  
            }
          });
        }, 600);
      } else if(width < 1080) {
        if(Controller.current) {
          Controller.current.kill();
        }
  
        if(timer.current) {
          clearTimeout(timer.current);
        }
  
        timer.current = setTimeout(() => {
          
          Controller.current = ScrollTrigger.create({
            trigger: viewport,
            endTrigger: viewport,
            start: "top top",
            end: "bottom top",
            markers: false,
            onEnterBack: (self) => {
              setFooterTrigger(false);
            },
            onLeave: (self) => {
              setFooterTrigger(true);
            },
          });
        }, 600);
      }

    }
    
    
    return () => {
      if(Controller.current) {
        Controller.current.kill();
      }
    }
  }, [viewport, footerEl, width])

  const viewportRef = React.useCallback((node) => {
    if( node !== null ) {
      setViewport(node);
    }
  },[])

  const footerRef = React.useCallback((node) => {
    if( node !== null ) {
      setFooterEl(node);
    }
  },[])
  
  return (
    <>
      <GlobalStyle />
      <EmailPrompt />
      <SideMenu />
      <Cart />
      <Account />
      <Seo />
      <SmoothieConceptOverlay />
      <GranolaConceptOverlay />
      <SkipNavLink />
      <div ref={viewportRef}>
        <Header plp={plp} navBorder={navBorder}/>
        <Main squared={squared} background={background}>
          <SkipNavContent>{children}</SkipNavContent>
        </Main>
      </div>
      <FooterWrapper ref={footerRef}>
      { !excludeFooter ? <Footer /> : null }
      </FooterWrapper>
    </>
  )
}
