import React from 'react';
import styled from 'styled-components';
import { AddMini } from '../AddToCart/AddToCart';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';

const Wrapper = styled.div` 
  ${fontstack.default}
  ${type('large')}
  font-weight: 300;
  color: ${props => props.theme === 'light' ? '#F2EADE': '#383B23'};
  border: none;
  outline: none;

  &::placeholder {
    color: ${props => props.theme === 'light' ? '#F2EADE': '#383B23'};
    opacity: 0.2;
  }
`

const SmallWrapper = styled(AddMini)` 
  font-weight: 400; 
  text-transform: none;
`

const EmailLabel = (props) => {
  const { children, ...rest } = props;
  return (
    <Wrapper {...rest}>{children}</Wrapper>
  )
}

export default EmailLabel;


const EmailLabelSmall = (props) => {
  const { children, ...rest } = props;
  return (
    <SmallWrapper {...rest}>{children}</SmallWrapper>
  )
}

export { EmailLabelSmall };