
export const addURLParams = (url, array) => {
  let newUrl = url;

  array.forEach((pair) => {
    newUrl += formatKeyValuePairs(pair);
  });

  return newUrl;
}

const formatKeyValuePairs = (object) => {
 return`&${encodeURIComponent(object.key)}=${encodeURIComponent(object.value)}`;
}