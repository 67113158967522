import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import debounce from "lodash.debounce"
import { StoreContext } from '../../context/store-context';
import { Quantity } from '../Forms/Forms';
import { ArrowLinkSmall } from '../ArrowLink/ArrowLink';
import { formatPrice } from '../../utils/format-price';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';
import CloseSvg from './assets/close.svg';

/**
 * CartProductHead
 */

 const CartProductsLineWrapper = styled.div`
 padding: 20px 0;
 display: flex;
 border-bottom: 1px solid #383B2340;
 position: relative;
 align-items: center;
 `

const CartProductsHeadWrapper = styled(CartProductsLineWrapper)`
  position: relative;
 align-items: stretch;
 display: grid;
 grid-template-columns: repeat(2,1fr);
grid-column-gap: 30px;
`

const CartProductsHeadGridCol = styled.div` 
  display: flex;
  justify-content: flex-end;
`

const CartProductsHeadColumn = styled.div`
 text-align: left;
 ${fontstack.default}
 ${type('m-body')}
 font-weight: 500;
 color: #383B23;

  ${media.large`
    ${type('body')}
    font-weight: 500;
  `}
`
const CartProductsHeadNourishmentsColumn = styled(CartProductsHeadColumn)`
 width: 100%;
 ${media.large`
    flex: 1;
 `}
`

const CartProductsHeadQuantityColumn = styled(CartProductsHeadColumn)`
 display: none;
 ${media.large`
   display: block;
   width: calc(15% + 100px);
 `}

 ${media.xlarge`
  width: calc(15% + 150px);
  `}
`

const CartProductsHeadPriceColumn = styled(CartProductsHeadColumn)`
 width: 100px;
 display: none;
 ${media.large`
   display: block;
 `}
  ${media.xlarge`
    width: 150px;
  `}
`
const CartProductsHead = () => {

 return (
   <CartProductsHeadWrapper>
     <CartProductsHeadGridCol>
      <CartProductsHeadNourishmentsColumn>
        Nourishments
      </CartProductsHeadNourishmentsColumn>
      <CartProductsHeadQuantityColumn >
        Quantity
      </CartProductsHeadQuantityColumn >
     </CartProductsHeadGridCol>
     <CartProductsHeadGridCol>
      <CartProductsHeadPriceColumn>
        Price
      </CartProductsHeadPriceColumn>
     </CartProductsHeadGridCol>
   </CartProductsHeadWrapper>
 )
}

export { CartProductsHead }

/**
* CartProductsList 
*/

const CartProductsList = styled.ul`
 list-style: none;
 border: 0;
 margin: 0;
 padding: 0;
`

export { CartProductsList }

/**
* CartProductsListItem
*/

const CartProductsListItemWrapper = styled(CartProductsLineWrapper).attrs({
 as: "li"
})`
 position: relative;
 align-items: stretch;
 display: grid;
 grid-template-columns: repeat(2,1fr);
grid-column-gap: 30px;
`

const CartProductsListItemWrapperGridCol = styled.div` 
  display: flex;
  justify-content: flex-end;
`

const CartProductsListItemColumn = styled.div`
 text-align: left;
 ${fontstack.default}
 ${type('body')}
 color: #383B23;
 z-index: 1;
`

const CartProductsListItemNourishmentsColumn = styled(CartProductsListItemColumn)`
 flex: 1;
`
const CartProductsListItemQtyColumn = styled(CartProductsListItemColumn)`
 width: 15%;
`
const QuantityDummy = styled.div` 
  width: 80px;
  margin-right: 60px;
  ${media.xlarge`
    width: 100px;
  `}
`
const CartProductsListItemRemoveColumn = styled(CartProductsListItemColumn)`
  width: 100px;

  ${media.xlarge`
    width: 150px;
  `}
`

const RemoveButton = styled.button` 
${fontstack.default}
 ${type('body')}
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
  cursor:pointer;
  opacity: ${props => props.active ? '1' : '0'};
  transition: opacity .2s ease;
`

const CartProductsListItemDisabled = styled.div` 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #FAF7F2;
  opacity: ${props => props.hide ? '0.8' : '0'};
  z-index: ${props => props.active ? '2' : '0'};
  transition: opacity .2s ease;
`


const CartProductsListItemPriceColumn = styled(CartProductsListItemColumn)`
  width: 100px;

  ${media.xlarge`
    width: 150px;
  `}
`

const CartProductsListItem = ({item}) => {
  const [ Active, setActive ] = useState(false); 
  const [ Hide, setHide ] = useState(false);
  const [quantity, setQuantity] = useState(item.quantity);

  const {
    removeLineItem,
    checkout,
    updateLineItem,
    lineItemProducts,
    loading,
  } = React.useContext(StoreContext)


  const handleRemove = () => {
    setHide(true)
    removeLineItem(checkout.id, item.id).then(()=>{
      setHide(false)
    })
  }

  const uli = debounce(
    (value) => {
      setHide(true)
      updateLineItem(checkout.id, item.id, value).then(()=>{
        setHide(false)
      })
    },
    300
  )
  // eslint-disable-next-line
  const debouncedUli = React.useCallback((value) => uli(value), [])


  const handleQuantityChange = (value) => {
    setQuantity(value)
    debouncedUli(value)
  }

  const subtotal = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount) * quantity
  )

  const variantTitle = item.variant.title !== 'Default Title' ? `(${item.variant.title})` : null;
  const productType = lineItemProducts && lineItemProducts.length > 0 ? lineItemProducts.filter((product)=> product.id === item.variant.product.id)[0].productType : null;
  const step = productType === 'icebar' ? 5 : 1;

 return (
   <CartProductsListItemWrapper onMouseEnter={()=>setActive(true)} onMouseLeave={()=>setActive(false)}>
     <CartProductsListItemDisabled active={loading} hide={Hide}/>
     <CartProductsListItemWrapperGridCol>
      <CartProductsListItemNourishmentsColumn>
        {item.title} {variantTitle || null}
      </CartProductsListItemNourishmentsColumn>
      <CartProductsListItemQtyColumn>
          <QuantityDummy><Quantity step={step} min='0' max='99' maxlength='2' theme='dark' callback={(val)=>handleQuantityChange(val)} value={quantity} circle activeButtons={Active}/></QuantityDummy>
      </CartProductsListItemQtyColumn>
      <CartProductsListItemRemoveColumn>
        <RemoveButton onClick={handleRemove}active={Active}>Remove</RemoveButton>
      </CartProductsListItemRemoveColumn>
     </CartProductsListItemWrapperGridCol>
     <CartProductsListItemWrapperGridCol>
      <CartProductsListItemPriceColumn>
        {subtotal}
      </CartProductsListItemPriceColumn>
     </CartProductsListItemWrapperGridCol>
   </CartProductsListItemWrapper>
 )
}

export { CartProductsListItem }


const CartProductsListCreateItem = ({item}) => {
  const [ Active, setActive ] = useState(false); 
  const [quantity, setQuantity] = useState(1);
  const [ Hide, setHide ] = useState(false);

  const {
    removeLineItem,
    checkout,
    updateLineItem,
    loading,
  } = React.useContext(StoreContext)


  const handleRemove = () => {
    const ids = item.map((product)=> { return product.id });
    setHide(true);
    removeLineItem(checkout.id, ids).then(()=>{
      setHide(false);
    })
  }

  let subtotal = 0;
  let contents = '';

  item.forEach((product,index)=>{
    subtotal += Number(product.variant.priceV2.amount) * product.quantity

    contents += index !== item.length - 1 ? `${product.quantity} ${product.title}, ` : `${product.quantity} ${product.title}`;
  })

  subtotal = formatPrice(
    item[0].variant.priceV2.currencyCode,
    subtotal
  );


 return (
   <CartProductsListItemWrapper onMouseEnter={()=>setActive(true)} onMouseLeave={()=>setActive(false)}>
     <CartProductsListItemDisabled active={loading} hide={Hide}/>
     <CartProductsListItemWrapperGridCol>
      <CartProductsListItemNourishmentsColumn>
        Create Your Own ({contents})
      </CartProductsListItemNourishmentsColumn>
      <CartProductsListItemQtyColumn>
        <QuantityDummy><Quantity step='1' min='1' max='1' maxlength='1' theme='dark' value={quantity} circle disabled/></QuantityDummy>
      </CartProductsListItemQtyColumn>
      <CartProductsListItemRemoveColumn>
        <RemoveButton onClick={handleRemove}active={Active}>Remove</RemoveButton>
      </CartProductsListItemRemoveColumn>

     </CartProductsListItemWrapperGridCol>
     <CartProductsListItemWrapperGridCol>
      <CartProductsListItemPriceColumn>
        {subtotal}
      </CartProductsListItemPriceColumn>
     </CartProductsListItemWrapperGridCol>
   </CartProductsListItemWrapper>
 )
}

export { CartProductsListCreateItem }
/**
 * CartProductsListItemMobile 
 */

 const CartProductsListItemMobileWrapper = styled(CartProductsLineWrapper).attrs({
  as: "li"
 })`
  position: relative;
  align-items: stretch;
`
const CartProductListItemMobileTitle = styled.h2`
  ${fontstack.default}
  ${type('m-body')}
  margin: 0;
  color: #383B23;
`

const CartProductsListItemMobileContent = styled.div` 
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 1;
`
const CartProductsListItemMobileContentBottom = styled.div` 
  display: flex;
  justify-content: space-between;
` 

const CartProductsListItemMobileContentPrice = styled.div` 
  ${fontstack.default}
  ${type('caption')}
  margin: 0;
  color: #383B23; 
`

const CartProductsListItemMobileContentRemove = styled.div` 
  ${fontstack.default}
  ${type('caption')}
  margin: 0;
  color: #383B23; 
`

const CartProductsListItemMobileQuantity = styled.div` 
  width: 80px;
  margin: 0 0 0 20px;
  position: relative;
  z-index: 1;
`

const CartProductsListItemMobile = ({item}) => {
  const [ Active, setActive ] = useState(false); 
  const [quantity, setQuantity] = useState(item.quantity);
  const [ Hide, setHide ] = useState(false);

  const {
    removeLineItem,
    checkout,
    updateLineItem,
    lineItemProducts,
    loading,
  } = React.useContext(StoreContext)


  const handleRemove = () => {
    setHide(true)
    removeLineItem(checkout.id, item.id).then(()=>{
      setHide(false)
    })
  }

  const uli = debounce(
    (value) => {
      setHide(true)
      updateLineItem(checkout.id, item.id, value).then(()=>{
        setHide(false)
      })
    },
    300
  )
  // eslint-disable-next-line
  const debouncedUli = React.useCallback((value) => uli(value), [])


  const handleQuantityChange = (value) => {
    setQuantity(value)
    debouncedUli(value)
  }

  const subtotal = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount) * quantity
  )

  const variantTitle = item.variant.title !== 'Default Title' ? `(${item.variant.title})` : null;
  const productType = lineItemProducts && lineItemProducts.length > 0 ? lineItemProducts.filter((product)=> product.id === item.variant.product.id)[0].productType : null;
  const step = productType === 'icebar' ? 5 : 1;

  return (
    <CartProductsListItemMobileWrapper>
      <CartProductsListItemDisabled active={loading} hide={Hide}/>
      <CartProductsListItemMobileContent>
        <CartProductListItemMobileTitle>{item.title} {variantTitle || null}</CartProductListItemMobileTitle>
        <CartProductsListItemMobileContentBottom>
          <CartProductsListItemMobileContentPrice>{subtotal}</CartProductsListItemMobileContentPrice>
          <CartProductsListItemMobileContentRemove onClick={handleRemove}>Remove</CartProductsListItemMobileContentRemove>
        </CartProductsListItemMobileContentBottom>
      </CartProductsListItemMobileContent>
      <CartProductsListItemMobileQuantity>
        <Quantity step={step} min='0' max='99' maxlength='2' theme='dark' callback={(val)=>handleQuantityChange(val)} value={quantity} circle activeButtons={true} vertical/>
      </CartProductsListItemMobileQuantity>
    </CartProductsListItemMobileWrapper>
  )
}

export { CartProductsListItemMobile }

const CartProductsListCreateItemMobile = ({item}) => {
  const [ Active, setActive ] = useState(false); 
  const [quantity, setQuantity] = useState(1);
  const [ Hide, setHide ] = useState(false);

  const {
    removeLineItem,
    checkout,
    updateLineItem,
    loading,
  } = React.useContext(StoreContext)


  const handleRemove = () => {
    const ids = item.map((product)=> { return product.id });
    setHide(true);
    removeLineItem(checkout.id, ids).then(()=>{
      setHide(false);
    })
  }

  let subtotal = 0;
  let contents = '';

  item.forEach((product,index)=>{
    subtotal += Number(product.variant.priceV2.amount) * product.quantity

    contents += index !== item.length - 1 ? `${product.quantity} ${product.title}, ` : `${product.quantity} ${product.title}`;
  })

  subtotal = formatPrice(
    item[0].variant.priceV2.currencyCode,
    subtotal
  );

  return (
    <CartProductsListItemMobileWrapper>
      <CartProductsListItemDisabled active={loading} hide={Hide}/>
      <CartProductsListItemMobileContent>
        <CartProductListItemMobileTitle>Create Your Own ({contents})</CartProductListItemMobileTitle>
        <CartProductsListItemMobileContentBottom>
          <CartProductsListItemMobileContentPrice>{subtotal}</CartProductsListItemMobileContentPrice>
          <CartProductsListItemMobileContentRemove onClick={handleRemove}>Remove</CartProductsListItemMobileContentRemove>
        </CartProductsListItemMobileContentBottom>
      </CartProductsListItemMobileContent>
      <CartProductsListItemMobileQuantity>
        <Quantity step='1' min='1' max='1' maxlength='1' theme='dark' value={quantity} vertical disabled/>
      </CartProductsListItemMobileQuantity>
    </CartProductsListItemMobileWrapper>
  )
}

export { CartProductsListCreateItemMobile }


/**
* CartProductsInfoLine
*/


const CartProductsInfoLineFirstColumn = styled(CartProductsHeadColumn)`
 flex-grow: 1;
 font-weight: ${props => props.regular ? '400' : '500'};
`

const CartProductsInfoLinePriceColumn = styled(CartProductsHeadColumn)`
  font-weight: ${props => props.regular ? '400' : '500'};
  ${media.large`
    width: 100px;
  `}

  ${media.xlarge`
    width: 150px;
  `}
`

const CartProductsInfoLine = ({title, price, regular}) => {

 return (
   <CartProductsLineWrapper>
     <CartProductsInfoLineFirstColumn regular={regular}>
       {title}
     </CartProductsInfoLineFirstColumn>
     <CartProductsInfoLinePriceColumn regular={regular}>
       {price}
     </CartProductsInfoLinePriceColumn>
   </CartProductsLineWrapper>
 )
}

export { CartProductsInfoLine }

/**
 * CartProductsInput
 */

 const CartProductsInputLeft = styled.div`
 flex-grow: 1;
 padding: 0 50px 0 0;
`

const CartProductsInputRight = styled.div`
 ${media.large`
  width: 100px;
 `}

 ${media.xlarge`
   width: 150px;
  `}
`

const CartProductsInputField = styled.input` 
  ${fontstack.default}
  ${type('m-body')}
  color: #383B23;
  border: 0;
  margin: 0;
  padding: 0;
  background: transparent;
  outline: none;
  width: 100%;
  display: block;

  ${media.large`
    ${type('body')}
  `}
`

const CartProductsContent = styled.div`
  ${fontstack.default}
  ${type('m-body')}
  color: #383B23;
  border: 0;
  margin: 0;
  padding: 0;
  background: transparent;
  outline: none;
  width: 100%;
  height: 22px;
  display: block;
  opacity: 0.6;
  ${media.large`
    ${type('body')}
  `}
`

const CartProductsInputButton = styled.button` 
  outline: none;
  border: 0;
  background: transparent;
  ${fontstack.default}
  ${type('m-body')}
  color: #383B23;
  width: 100%;
  cursor: pointer;
  text-align: left;
  display: block;
  margin:0;
  padding: 0;
  ${media.large`
    ${type('body')}
  `}
  
`

const CloseIcon = styled(CloseSvg)` 
  stroke: #383B23;
  position: absolute;
  right: 0;
`
const CartProductsInput = ({label, placeholder, onSet, onUnset, setLabel, setContent, isSet, onValueUpdate, initValue}) => {
  const [ InputValue, setInputValue ] = useState(initValue||'');

  const onChange = (e) =>{
    onValueUpdate(e.target.value);
    setInputValue(e.target.value);
  }

 return (
   <CartProductsLineWrapper>
     <CartProductsInputLeft> 
       { isSet ? <CartProductsContent>{setContent}</CartProductsContent> : <CartProductsInputField type="text" value={InputValue} onChange={onChange} placeholder={placeholder}/>}
     </CartProductsInputLeft>
     { isSet ? 
     <CloseIcon onClick={()=>{setInputValue(''); onValueUpdate(''); onUnset(); }}/> :
     <CartProductsInputRight>
        <CartProductsInputButton onClick={onSet}>{label}</CartProductsInputButton>
     </CartProductsInputRight>
     }
   </CartProductsLineWrapper>
 )
}

export { CartProductsInput }

const CartGift = () => {
  const {
    setNote,
    checkout
  } = React.useContext(StoreContext);

  const [ Set, setSet ] = useState(checkout.note || false);
  const value = useRef(checkout.note || null);
  

  const valueUpdate = (val) => {
    value.current = val;
  }
  
  const onSet = () => {
    setSet(true);

    setNote(value.current);
  }

  const onUnset = () => {
    setSet(false);
    setNote(null);
  }

  return (
    <CartProductsInput 
      label='Add' 
      placeholder='Gifting a friend? Write your message here.' 
      setContent={value.current}
      onSet={onSet} 
      onUnset={onUnset}
      onValueUpdate={valueUpdate} 
      initValue={value.current}
      isSet={Set}/>
  )
}

export { CartGift }

const CartGiftMobileWrapper = styled.div` 
  position: relative;
`

const CartGiftMobileStatement = styled.h2` 
  position: relative;
  margin: 20px 0 0;
  ${fontstack.default}
  ${type('m-body')}
  font-weight: 500;
  color: #383B23;
`

const CartGiftMobileTextArea = styled.textarea` 
  width: 100%;
  height: 120px;
  border: 1px solid #383B23;
  outline: none;
  margin: 20px 0 10px;
  padding: 10px;
  background: transparent;

  &:disabled {
    opacity: 0.4;
  }
`

const CartGiftMobileButtons = styled.div` 
  position: relative;
  height: 25px;
`

const CartGiftMobileAdd = styled(ArrowLinkSmall)` 
  ${fontstack.default}
  ${type('m-body')}
  font-weight: 500;
  text-transform: uppercase;
`

const CartGiftMobileRemove = styled(CloseSvg)` 
  stroke: #383B23;
  margin: 4px 0 0;
  display: inline-block;
`

const CartGiftMobile = () => {
  const {
    setNote,
    checkout
  } = React.useContext(StoreContext);

  const [ Set, setSet ] = useState(checkout.note || false);
  const value = useRef(checkout.note || null);

  const onChange = (e) => {
    value.current = e.target.value;
  }

  const onSet = () => {
    setSet(true);
    setNote(value.current);
  }

  const onUnset = () => {
    setSet(false);
    setNote(null);
  }

  return (
  <CartGiftMobileWrapper>
    <CartGiftMobileStatement>Gifting for a friend? Write your message below.</CartGiftMobileStatement>
    <CartGiftMobileTextArea onChange={onChange} disabled={Set}>{value.current}</CartGiftMobileTextArea>
    <CartGiftMobileButtons>
    { Set ? <CartGiftMobileRemove  onClick={onUnset}/> : <CartGiftMobileAdd onClick={onSet}>Add</CartGiftMobileAdd>}
    </CartGiftMobileButtons>
  </CartGiftMobileWrapper>
  )
}

export { CartGiftMobile }

const CartDiscount = ({minimum,domestic}) => {
  const {
    addDiscount,
    removeDiscount,
    checkout
  } = React.useContext(StoreContext);

  const minimumManuallyRemoved = useRef(false);

  useEffect(() => {
    /**
     * Set the minimum discount code
     */
    const minAmount = minimum && minimum.prerequisiteSubtotalRange.greaterThanOrEqualTo ? minimum.prerequisiteSubtotalRange.greaterThanOrEqualTo : null;
    const subtotalPrice = checkout && checkout.lineItemsSubtotalPrice ? checkout.lineItemsSubtotalPrice.amount : 0;
    const minimumReached = minimum && Number(minAmount) <= Number(subtotalPrice) ?  true : false; 

    if(minimumReached && !discountCode && !minimumManuallyRemoved.current) {

      addDiscount('MINIMUMREACHED');
    }
  }, []);
  
  const value = useRef('');

  
  const discountCode = checkout && checkout.discountApplications && checkout.discountApplications.length > 0 && checkout.discountApplications[0].applicable ? checkout.discountApplications[0] : false;

  const valueUpdate = (val) => {
    value.current = val;
  }
  
  const onSet = () => {
    addDiscount(value.current);
  }

  const onUnset = () => {
    removeDiscount();
    minimumManuallyRemoved.current = true;
  }

   

  return (
    <CartProductsInput 
      label='Apply' 
      placeholder='Enter your promotional code'
      setContent={discountCode ? discountCode.code : value.current}
      onSet={onSet} 
      onUnset={onUnset}
      onValueUpdate={valueUpdate} 
      initValue={discountCode ? discountCode.code : ''}
      isSet={discountCode ? true : false}
      />
  )
}

export { CartDiscount }

/**
 * CartRadio
 */

 const LabelWrapper = styled.label`
 display: flex;
 align-items: center;
 justify-content: space-between;
 cursor: pointer;
 width: 100%;
 padding: 20px 0;
 &:not(:last-of-type) {
   margin: 0 30px 0 0;
 }
`

const Label = styled.div`
 ${fontstack.default}
 ${type('m-body')}
 color:#383B23;

 ${media.large`
   ${type('body')}
 `}
`

const Dot = styled.div`
 width: 13px;
 height: 13px;
 background: transparent;
 border-radius: 50%;
 margin: 0 10px 0 0;
 border: 1px solid #383B23;
 transition: background .2s ease;

 ${media.large`
  width: 20px;
  height: 20px;
 `}
`

const Input = styled.input`
 display: none;

 &:checked ~ ${Dot} {
   background:#383B23;
 }
`

const CartRadio = (props) => {
 const { children, className, ...rest } = props;

 return (
   <LabelWrapper className={className}>
     <Input type="radio" {...rest}/>
     <Label>{children}</Label> 
     <Dot/>
   </LabelWrapper>
 )

}

export { CartRadio }

