import React from 'react';
import styled, { css } from 'styled-components';
import { media } from '../../utils/mediaQuery';

const gutterStyle = css` 
  box-sizing: border-box;
  padding: 0 20px;
  ${media.large`
    padding: 0 30px;
  `}
`

const containStyle = css` 
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto; 
`

const mediumStyle = css` 
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto; 
`

const tightStyle = css` 
  max-width: 768px;
  margin-left: auto;
  margin-right: auto; 
`

const smallStyle = css`
  margin: 80px 0;
  ${media.medium`
    margin: 120px 0;
  `}
`

const largeStyle = css`
  margin-top: 96px;
  margin-bottom: 96px;
  ${media.medium`
    margin-top: 256px;
    margin-bottom: 256px;
  `}
`

const standardStyle = css`
  margin-top: 80px;
  margin-bottom: 80px;
  ${media.medium`
    margin-top: 145px;
    margin-bottom: 145px;
  `}
`

const Wrapper = styled.div` 
  position: relative;
  overflow: hidden;
  ${standardStyle}

  ${ props => props.small ? css`
    ${smallStyle}
  ` : null}

  ${ props => props.large ? css`
    ${largeStyle}
  ` : null}


  ${ props => props.none ? css`
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  ` : null}

  ${({ contain }) => contain && `
    ${containStyle}
  `}

  ${({ tight }) => tight && `
    ${tightStyle}
  `}

  ${({ medium }) => medium && `
    ${mediumStyle}
  `}

  ${ props => !props.nogutter ? css`
    ${gutterStyle}
  ` : null}
  
`

const Section = (props) => {
  const { children, small, large, full, none, contain, tight, medium, nogutter, ...rest} = props;
  return (
    <Wrapper
      {...rest}
      small={small} 
      large={large}
      full={full} 
      none={none}
      contain={contain}
      tight={tight} 
      medium={medium}
      nogutter={nogutter}>
      { children }
    </Wrapper>
  )
}

export default Section;