import React, { useEffect } from 'react';
import styled from 'styled-components';
import { media } from '../../utils/mediaQuery';
import CloseIcon from './assets/close.svg';

const Wrapper = styled.div`
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background: #383B23;
  transition: opacity .2s ease;
  overflow: hidden;

  ${({state}) => state === 'entering' && `
    opacity: 0;
  `}

  ${({state}) => state === 'entered' && `
    opacity: 1;
  `}

  ${({state,pos}) => state === 'exiting' && `
    opacity: 0;
  `}
`

const Box = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: scroll;
  overflow-x: hidden;
`

const Close = styled(CloseIcon)`
  position: fixed;
  width: 15px;
  right: 30px;
  top: 30px;
  z-index: 2;
  cursor: pointer;

  & line {
    stroke: #F2EADE;
  }

`

const Overlay = (props) => {
  const { children, onClose, state, ...rest} = props;
  
  useEffect(() => {
    if(state === 'entered') {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

  }, [state]);
  return (
    <Wrapper state={state}>
      <Box {...rest}>
        <Close onClick={onClose || null} />
        { children }
      </Box>
    </Wrapper>
  )
}

export default Overlay;