import { css } from 'styled-components';

export const linkhover = (color, active, leave) => css`
  &:before {
    content: '';
    width: 100%;
    height: 1px;
    background-color: ${color || '#383B23'};
    bottom: 0;
    left: 0;
    position: absolute;
    transform: ${active ? 'scaleX(1)' : 'scaleX(0)'};
    transform-origin: right center;
    transition: transform .3s cubic-bezier(0.4, 0, 0, 1);
    pointer-events: none;
  }

  &:hover:before {
    transform: ${active && leave ? 'scaleX(0)' : 'scaleX(1)'};
    transform-origin: left center;
  }
`
