import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import Bracket from '../Bracket/Bracket';
import Section from '../Section/Section';
import { StoreContext } from '../../context/store-context';
import { CartProductsHead, CartProductsList, CartProductsListItem, CartProductsListCreateItem, CartProductsListItemMobile, CartProductsListCreateItemMobile ,CartProductsInfoLine, CartGift, CartGiftMobile, CartDiscount} from './CartComponents';
import { formatPrice } from '../../utils/format-price';
import { media } from '../../utils/mediaQuery';

const CartProductsSection = styled(Section)`
  margin: 10px 0 0;

  ${media.large`
    margin: 40px 0 60px;
 `}
`

const CartProductsBottom = styled.div` 
  ${media.large`
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-column-gap: 30px;
 `}
`

const CartProductsBottomCol = styled.div` 
  position: relative;
`

const CartProducts = ({minimum,domestic}) => {
  const { checkout, width } = React.useContext(StoreContext)

  return (
    width >= 1080 ? <DesktopLayout minimum={minimum} domestic={domestic}/> : <MobileLayout minimum={minimum} domestic={domestic}/>
  )
}

export default CartProducts;

const getCreateLineItems = (lineItems) => {
  return lineItems.reduce((acc,curr) => {
    const customAttributes = curr.customAttributes;
    const createId = customAttributes.filter((obj)=> obj.key==='Create-Id');

    if(createId && createId.length > 0 && acc) {
      const id = createId[0].value.replace(/-/g, "")
      if(acc[id]) {
        acc[id].push(curr)
      } else {
        acc[id] = new Array();
        acc[id].push(curr);
      }
    }

    return acc;
  },{})
}

const DesktopLayout = ({minimum,domestic}) => {
  const { getLocations, checkout, locations, locationCalendars, loading, addMethodToCheckout, updateLineItemsWithZapietId } = React.useContext(StoreContext)

  const createObjects = checkout ? getCreateLineItems(checkout.lineItems) : null;
  return (
    <Bracket bottom topShift zIndex={2} background='#FAF7F2'>
      <CartProductsSection>
        <CartProductsHead />
        <CartProductsList>
          {checkout ? checkout.lineItems.map((item) => {
            const customAttributes = item.customAttributes;
            const createId = customAttributes.filter((obj)=> obj.key==='Create-Id');
            if(createId && createId.length > 0)
              return 

            return <CartProductsListItem item={item} key={item.id}/>
          }) : null }

          { createObjects ? Object.keys(createObjects).map((key)=>{
            const create = createObjects[key];
            return <CartProductsListCreateItem item={create} key={key}/>
          }): null}

        </CartProductsList>
        <CartProductsBottom>
          <CartProductsBottomCol>
            <CartGift/>
          </CartProductsBottomCol>
          <CartProductsBottomCol>
            <CartDiscount minimum={minimum} domestic={domestic}/>
            <CartProductsInfoLine title='Subtotal' price={formatPrice(
                      checkout.subtotalPriceV2.currencyCode,
                      checkout.subtotalPriceV2.amount
                    )}/>
            {/*<CartProductsInfoLine title='Shipping' price='FREE' regular/>
            <CartProductsInfoLine title='Total' price={formatPrice(
                      checkout.totalPriceV2.currencyCode,
                      checkout.totalPriceV2.amount
            )} />*/}
          </CartProductsBottomCol>
        </CartProductsBottom>
      </CartProductsSection>
    </Bracket>
  )
}

const MobileLayout = ({minimum,domestic}) => {
  const { getLocations, checkout, locations, locationCalendars, loading, addMethodToCheckout, updateLineItemsWithZapietId } = React.useContext(StoreContext)
  const createObjects = checkout ? getCreateLineItems(checkout.lineItems) : null;
  return (
    <Bracket bottom topShift zIndex={2} background='#FAF7F2'>
      <CartProductsSection>
        <CartProductsHead />
        <CartProductsList>
          {checkout ? checkout.lineItems.map((item) => {
            const customAttributes = item.customAttributes;
            const createId = customAttributes.filter((obj)=> obj.key==='Create-Id');
            if(createId && createId.length > 0)
              return 

            return <CartProductsListItemMobile item={item} key={item.id}/>
          }) : null }

          { createObjects ? Object.keys(createObjects).map((key)=>{
            const create = createObjects[key];
            return <CartProductsListCreateItemMobile item={create} key={key}/>
          }): null}
        </CartProductsList>
        <CartProductsBottom>
          <CartProductsBottomCol>
            <CartDiscount minimum={minimum} domestic={domestic}/>
            <CartProductsInfoLine title='Subtotal' price={formatPrice(
                      checkout.subtotalPriceV2.currencyCode,
                      checkout.subtotalPriceV2.amount
                    )}/>
            {/*        
            <CartProductsInfoLine title='Shipping' price='Free' regular/>
            <CartProductsInfoLine title='Total' price={formatPrice(
                      checkout.totalPriceV2.currencyCode,
                      checkout.totalPriceV2.amount
            )} />*/}
          </CartProductsBottomCol>
        </CartProductsBottom>
        <CartGiftMobile />
      </CartProductsSection>
    </Bracket>
  )
}