import React, {useState} from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  
  width: 100%;
  height: 100vh;
  background: #383B23;
  box-sizing: border-box;
  padding: 30px 30px 50px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
  transition: border-radius .2s ease;

  ${({squared}) => squared && `
    border-radius: 0px;
  `}

`

const Inner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const SideMenuPanel = (props) => {
  const { children, squared } = props;

  return (
    <Wrapper squared={squared}>
      <Inner>
        { children }
      </Inner>
    </Wrapper>
  )
}

export default SideMenuPanel;