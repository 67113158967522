import * as React from "react"
import styled from "styled-components"
import { fontstack } from '../utils/fontstack';
import { type } from '../utils/type';
import { media } from '../utils/mediaQuery';
import { toastWrapper, hiding, showing } from "./toast.module.css"
import Icon from './Cart/assets/close.svg';


const CloseIcon = styled(Icon)`
  position: absolute;
  right: 20px;
  stroke: #f3efd7;
  cursor: pointer;
  z-index: 10;
`
export function Toast({ show, duration = 200, className, children, ...props }) {
  const [visible, setVisible] = React.useState(show)
  const [animation, setAnimation] = React.useState("")

  React.useEffect(() => {
    if (show) {
      setVisible(true)
    }
    const timeout = setTimeout(() => {
      setAnimation("")
      setVisible(show)
    }, duration)
    setAnimation(show ? showing : hiding)
    return () => clearTimeout(timeout)
  }, [show, duration])

  return visible ? (
    <div onClick={() => { setVisible(false) }}
      className={[toastWrapper, animation, className].join(" ")}
      {...props}
    >{children} <CloseIcon/></div>
  ) : null
}


const ToastMessageWrapper = styled.div` 
  ${fontstack.default}
  ${type('m-body')}
  color: #F2EADE;

  ${media.large`
    ${type('body')}
  `}
`

const ToastMessage = (props) => {
  const { children } = props;

  return (
    <ToastMessageWrapper>
      {children}  
    </ToastMessageWrapper>
  )
}

export { ToastMessage }