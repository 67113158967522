import React, {useState} from 'react';
import styled from 'styled-components';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';
import Icon from './assets/arrow.svg';
import Tag from '../Tag/Tag';

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  cursor: pointer;
`

const Label = styled.div`
  ${fontstack.default}
  ${type('large')}
  font-weight: 300;
  color: #F3EFD7;
  opacity: ${props => props.hover ? '0.4' : '1'};
  transition: opacity .2s ease;
`

const SuperScript = styled.sup`
  font-size: 24px;
`

const Arrow = styled(Icon)`
  stroke: #F3EFD7;
  margin: 0 0 0 10px;
  display: inline-block;
`

const TertiaryWrapper = styled.div`
  height: 40px;
  opacity: ${props => props.hover ? '1' : '0'};
  transition: opacity .2s ease;
  display: none;
`
const SideMenuTag = styled(Tag)`
  margin: 0 0 0 30px;
  text-transform: uppercase;
`

const SideMenuItem = (props) => {
  const { children, sup, tag, ...rest } = props; 
  const [ isHover, setIsHover ] = useState(false);

  return (
    <Wrapper onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} {...rest}>
      <Label hover={isHover}>
        {children}
        {sup ? <SuperScript>{sup}</SuperScript> : null}
      </Label>
        <TertiaryWrapper hover={isHover}>
          { tag ? <SideMenuTag theme="light">{tag}</SideMenuTag> : null}
          <Arrow />
        </TertiaryWrapper>
    </Wrapper>
  )
}

export default SideMenuItem;

const GroupWrapper = styled.div`
  margin: 25px 0 0;

  ${media.large`
    margin: 50px 0 0;
  `}
`

const SideMenuItemGroup = (props) => {
  const { children } = props;

  return (
    <GroupWrapper>
      { children }
    </GroupWrapper>
  )
  
}

export { SideMenuItemGroup }

const SideMenuItemWrap = styled.div` 
  &:not(&:last-of-type) {
    margin: 0 0 10px;
  }
`

const SideMenuItemWrapper = (props) => {
  const { children } = props;
  return (
    <SideMenuItemWrap>
      { children }
    </SideMenuItemWrap>
  )
}

export { SideMenuItemWrapper };