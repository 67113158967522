import React from 'react';
import styled from 'styled-components';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';

const Wrapper = styled.div`
  ${fontstack.default}
  ${type('m-body')}
  padding: 8px 15px;
  border-radius: 10px;
  display: inline-block;
  border-width: 1px;
  border-style: solid;
  white-space: nowrap;

  ${({ theme }) => theme === 'light' ? `
    background: #F3EFD7;
    color: #383B23;
    border-color: #F3EFD7;
  ` : theme === 'dark' ? `
    background: #383B23;
    color: #F3EFD7;
    border-color: #383B23;
  ` : theme === 'outline' ? `
    background: transparent;
    color: #383B23;
    border-color: #383B23;
  ` : `
    background: #383B23;
    color: #F3EFD7;
    border-color: #383B23;
  `}

  ${media.large`
    ${type('body')}
    padding: 10px 15px;
    line-height: 1;
  `}
`
const Tag = (props) => {
  const { children, theme, ...rest} = props;
  return (
    <Wrapper theme={theme} {...rest}>
      {children}
    </Wrapper>
  )
}

export default Tag;