import React, { useState, useEffect } from 'react';
import { Transition } from 'react-transition-group';
import { StoreContext } from "../../context/store-context";
import AccountLogin from './AccountLogin';
import AccountCreate from './AccountCreate';
import AccountRecover from './AccountRecover';

const Account = () => {
  const { toggleLogin, loginOpen } = React.useContext(StoreContext);
  const [ Type, setType ] = useState();

  const switchType = (type) => {
    setType(type);
  }

  return (
    <Transition in={loginOpen} timeout={{
      enter: 0,
      exit: 200,
     }}>
       {state => { 
         if(state !== 'exited') {
            if(Type === 'create') {
              return <AccountCreate state={state}  callback={switchType} />
            } else if (Type === 'recover') {
              return <AccountRecover state={state}  callback={switchType} />
            } else {
              return <AccountLogin state={state} callback={switchType}/>
            }
         } else {
           setType(null);
         }
       }}
     </Transition>
  )
}

export default Account;