import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Link, navigate } from 'gatsby';
import { StoreContext } from "../../context/store-context";
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { linkhover } from '../../utils/lineHover';

import NuteBrand from '../NuteBrand/NuteBrand';
import { Toast, ToastMessage } from '../toast';

const Nav = styled.nav` 
  ${fontstack.default}
  background: #F2EADE;
  border: none;
  outline: none;
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  box-sizing: border-box;
  border-radius: 0 0 40px 40px;
  position: relative;

  ${({border}) => border && `
    &:after {
      position: absolute;
      left: 30px;
      right: 30px;
      bottom: 0;
      display: block;
      content: '';
      height: 1px;
      background-color: #383B23;
    }
  `}
  
`

const Logo = styled(NuteBrand)`
  height: 25px;
`

const LogoWrapper = styled.div` 
  position: absolute;
  left: 50%;
  
  & > a {
    position: relative;
    margin-left: -50%;
    display: block;
  }

`

const NavItemGroup = styled.div`
  position: relative;
`

const Navigation = (props) => {
  const { toggleMenu, menuOpen, toggleCart, cartOpen, toggleLogin, checkout, loading, didJustAddToCart, getCurrentUser, getCurrentUsername, username } = React.useContext(StoreContext);
  const ShopRef = useRef();
  const DigestRef = useRef();
  const GroupRef = useRef();
  const CurrentHover = useRef();
  const Timer = useRef(null);

  useEffect(() => {
    window.addEventListener("mousemove", checkHover, true);

    return () => {
      window.removeEventListener("mousemove", checkHover, true);
    }
  }, [])

  const fireTimer = () => {
    console.log(`timer fired for ${CurrentHover.current}`);
    toggleMenu(CurrentHover.current);
  }

  const checkHover = (e) => {

    if(ShopRef.current && DigestRef.current && GroupRef.current) {

      if(GroupRef.current.contains(e.target) && !menuOpen){
        if(!Timer.current) {
          console.log('timer added');
          Timer.current = setTimeout(fireTimer,200);
        }
      } else {

        if(Timer.current) {
          console.log('clear timer');
          clearTimeout(Timer.current);
          Timer.current = null;
        }

        if(CurrentHover.current) {
          CurrentHover.current = null;
        }
      }

      if(ShopRef.current.contains(e.target) && !menuOpen) {
        CurrentHover.current = 'shop';
      } else if(DigestRef.current.contains(e.target) && !menuOpen){
        CurrentHover.current = 'digest';
      }
    }
  }

  const items = checkout ? checkout.lineItems : []

  const quantity = items.reduce((total, item) => {
    return total + item.quantity
  }, 0)


  return (
    <Nav {...props}>
      <NavItemGroup ref={GroupRef}>
        <NavItem ref={ShopRef}>Shop</NavItem>
        <NavItem ref={DigestRef}>Digest</NavItem>
      </NavItemGroup>
      <LogoWrapper>
        <Link to='/' onClick={()=> { if(cartOpen) { toggleCart() } } }><Logo /></Link>
      </LogoWrapper>
      <NavItemGroup>
        { getCurrentUser() && getCurrentUser().customerAccessToken.accessToken && username ? 
        <Link to='/account' onClick={()=> { if(cartOpen) { toggleCart() } } }><NavItem>Hello, {username}</NavItem></Link>
        : getCurrentUser() && getCurrentUser().customerAccessToken.accessToken ? <Link to='/account' onClick={()=> { if(cartOpen) { toggleCart() } } }><NavItem>Account</NavItem></Link> : <NavItem onClick={()=>toggleLogin()}>Login</NavItem> }
        <NavItem onClick={toggleCart}>Cart ({quantity})</NavItem>
      </NavItemGroup>
      <Toast show={didJustAddToCart}>
        {(
          <ToastMessage>
            Nourishment added.
          </ToastMessage>
        )}
      </Toast>
    </Nav>
  )
}

export default Navigation;


const NavItemWrapper = styled.a`
  display: inline-block;
  ${fontstack.default}
  ${type('body')}
  color: ${props => props.theme === 'light' ? '#F2EADE': '#383B23'};
  cursor: pointer;
  user-select: none;
  padding: 0 15px;
  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`

const NavItemInner = styled.div` 
  position: relative;
  ${props => linkhover(props.theme === 'light' ? '#F2EADE': '#383B23',props.active)}
`

const NavItem = React.forwardRef((props, ref) => {
  const { children, active, theme, ...rest } = props;
  return (
    <NavItemWrapper ref={ref} active={active} theme={theme} {...rest}>
      <NavItemInner theme={theme} active={active} >
        {children}
      </NavItemInner>
    </NavItemWrapper>
  )

})

export { NavItem }