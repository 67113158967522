import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Link, navigate } from 'gatsby';
import { StoreContext } from "../../context/store-context";
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { Toast, ToastMessage } from '../toast';
import NuteBrand from '../NuteBrand/NuteBrand';

const Nav = styled.nav` 
  ${fontstack.default}
  border: none;
  outline: none;
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 20px 20px;
  box-sizing: border-box;
  position: relative;
  z-index: 2;

  ${({border}) => border && `
    &:after {
      position: absolute;
      left: 20px;
      right: 20px;
      bottom: 0;
      display: block;
      content: '';
      height: 1px;
      background-color: #383B23;
    }
  `}
  
`
const Logo = styled(NuteBrand)`
  height: 17px;
  fill: ${props => props.theme === 'light' ? '#F2EADE': '#383B23'};
`

const LogoWrapper = styled.div` 
  position: absolute;
  left: 50%;
  
  & > a {
    position: relative;
    margin-left: -50%;
    display: block;
  }

`


const NavigationMobile = (props) => {
  const { toggleMobileMenu, mobileMenuOpen, checkout, toggleCart, cartOpen, didJustAddToCart } = React.useContext(StoreContext);

  const items = checkout ? checkout.lineItems : []

  const quantity = items.reduce((total, item) => {
    return total + item.quantity
  }, 0)

  return (
  <Nav theme={mobileMenuOpen ? 'dark' : 'light'} {...props}>
    <NavItemMobile theme={mobileMenuOpen ? 'light' : 'dark'} onClick={()=>{ if(cartOpen) { toggleCart() } toggleMobileMenu()}}>{mobileMenuOpen ? 'Close' : 'Menu'}</NavItemMobile>
    <LogoWrapper>
      <Link to='/' onClick={()=> { if(cartOpen) { toggleCart() } if(mobileMenuOpen) { toggleMobileMenu() } } }><Logo theme={mobileMenuOpen ? 'light' : 'dark'} /></Link>
    </LogoWrapper>
    <NavItemMobile theme={mobileMenuOpen ? 'light' : 'dark'} onClick={()=>{  if(mobileMenuOpen) { toggleMobileMenu() } toggleCart() }}>Cart ({quantity})</NavItemMobile>
    <Toast show={didJustAddToCart}>
      {(
        <ToastMessage>
          Nourishment added.
        </ToastMessage>
      )}
    </Toast>
  </Nav>
  )
}

export default NavigationMobile;


const NavItemWrapper = styled.a`
  display: inline-block;
  ${fontstack.default}
  ${type('m-body')}
  line-height: 1;
  color: ${props => props.theme === 'light' ? '#F2EADE': '#383B23'};
  cursor: pointer;
  padding: 0 15px;
  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`

const NavItemInner = styled.div` 
  border-bottom: ${props => props.active ? '1px solid #F2EADE' : '1px solid #F2EADE0'};
`

const NavItemMobile = (props) => {
  const { children, active, theme, ...rest } = props;
  return (
    <NavItemWrapper active={active} theme={theme} {...rest}>
      <NavItemInner active={active} >
        {children}
      </NavItemInner>
    </NavItemWrapper>
  )

}

export { NavItemMobile }