import React from 'react';
import styled from 'styled-components';
import Section from '../Section/Section';
import ModuleDivider from '../ModuleDivider/ModuleDivider';
import HtmlParser from '../HtmlParser/HtmlParser';
import { Transition } from 'react-transition-group';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { StoreContext } from '../../context/store-context';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';
import Overlay from '../Overlay/Overlay';
import GraphicOne from './assets/crunch.png';
import GraphicTwo from './assets/quench.png';

const Wrapper = styled.div`
  overflow: hidden;
  background: #383B23;
`

const TopGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1,1fr);
  margin: 40px 0 0;

  ${media.large`
    grid-template-columns: repeat(3,1fr);
    grid-column-gap: 30px;
    margin: 100px 0 0;
  `}
`

const BottomGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-column-gap: 30px;
  margin: 30px 0 0;
`

const Statement = styled.p`
  ${fontstack.default}
  color: #F2EADE;
  ${type('m-large')}
  font-weight: 300;
  margin: 0;
  ${media.large`
    ${type('large')}
    font-weight: 300;
  `}
`

const GraphicBox = styled.div` 
  border: 1px solid #F2EADE;
  border-radius: 10px;
  overflow: hidden;
  isolation: isolate;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  
  margin: ${props => props.mobileMargin ? '40px 0 0' : '0'};

  ${media.large`
    border-radius: 20px;
  `}
`

const GraphicImage = styled.img` 
  position: relative;
  width: 100%;
`

const GraphicVideo = styled.video`
  position: relative;
  width: 100%;  
`

const WaysTitle = styled.h2` 
  ${fontstack.default}
  color: #F2EADE;
  ${type('caption')}
  font-weight: 500;
  text-transform: uppercase;
  margin: 100px 0 40px;

  ${media.large`
    ${type('body')}
    font-weight: 500;
    margin: 0;
  `}
`

const MethodTitle = styled.div` 
  ${fontstack.default}
  color: #F2EADE;
  ${type('m-body')}
  margin: 15px 0;

  ${media.large`
    ${type('body')}
    letter-spacing: 0.030em;
    margin: 0 0 15px;
  `}
`

const Steps = styled.div` 
  ${fontstack.default}
  color: #F2EADE;
  ${type('m-body')}

  ${media.large`
    ${type('body')}
  `}
`

const Divider = styled.hr` 
  border-color: #F2EADE;
  margin: 0 0 20px;
`

const Sup = styled.sup`
  font-size: 12px;
  line-height: 1;
  margin: 0 15px 0 0;
`

const LearnMoreWrapper = styled.div`
  position: relative;
  margin: 30px 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`

const LearnMore = styled.div`
${fontstack.default}
  ${type('body')}
  font-weight: 400;
  color: #383B23;
  text-decoration: underline;
  cursor: pointer;
  grid-column: 1 / span 2;

  ${media.large`
    grid-column: 2;
  `}
`

const DesktopLayout = (data) => {
  const {
    main_copy,
    sub_copy,
    method,
  } = data;

  return (
    <>
      <TopGrid>
        <Statement>{main_copy}</Statement>
        { method && method.length > 0 ? 
        method.map((section,i) => {
          const image = section.image;
          const video = section.video;
          if(video.url) {
            return <GraphicBox key={i}><GraphicVideo playsInline autoPlay muted loop>
                <source src={video.url} type="video/mp4" />
              Your browser does not support the video tag.
              </GraphicVideo></GraphicBox>
          } else if(image.url) {
            return <GraphicBox key={i}><GraphicImage src={image.url} alt={image.alt} /></GraphicBox>
          }
          
        }) : null }
      </TopGrid>
      <BottomGrid>
        <div>
          <WaysTitle>{sub_copy}</WaysTitle>
        </div>
        { method && method.length > 0 ?
          method.map((section,i) => {
            const titleRichText = section.title.richText[0];
            const titleHtml = section.title.html;

            const contentRichText = section.content.richText[0];
            const contentHtml = section.content.html;
            return <div key={i}>
              <MethodTitle><HtmlParser>{titleRichText && titleRichText.type === 'preformatted' ? titleRichText.text : titleHtml}</HtmlParser></MethodTitle>
              <Divider/>
              <Steps>
                <HtmlParser>{contentRichText && contentRichText.type === 'preformatted' ? contentRichText.text : contentHtml}</HtmlParser>
              </Steps>
            </div>
          }) : null }
        
      </BottomGrid>
    </>
  )
}

const MobileLayout = (data) => {
  const {
    main_copy,
    sub_copy,
    method
  } = data;

  const graphicOneImage = method && method.length > 0 ? method[0].image : null;
  const graphicTwoImage = method && method.length > 1 ? method[1].image : null;

  const graphicOneVideo = method && method.length > 0 ? method[0].video : null;
  const graphicTwoVideo = method && method.length > 1 ? method[1].video : null;
  
  return (
    <>
      <TopGrid>
        <Statement>{main_copy}</Statement>
        <WaysTitle>{sub_copy}</WaysTitle>
        <GraphicBox>
          { graphicOneVideo.url ? <GraphicVideo playsInline autoPlay muted loop>
                <source src={graphicOneVideo.url}type="video/mp4" />
              Your browser does not support the video tag.
            </GraphicVideo> : graphicOneImage.url ? <GraphicImage src={graphicOneImage.url} alt={graphicOneImage.alt} /> : null }
        </GraphicBox>
        { method && method.length > 0 ?
          method.map((section, index) => {
            const titleRichText = section.title.richText[0];
            const titleHtml = section.title.html;

            const contentRichText = section.content.richText[0];
            const contentHtml = section.content.html;
            return <>
              <div>
                <MethodTitle><HtmlParser>{titleRichText && titleRichText.type === 'preformatted' ? titleRichText.text : titleHtml}</HtmlParser></MethodTitle>
                <Divider/>
                <Steps>
                  <HtmlParser>{contentRichText && contentRichText.type === 'preformatted' ? contentRichText.text : contentHtml}</HtmlParser>
                </Steps>
              </div>
              { index === 0 ? <GraphicBox mobileMargin>
                { graphicTwoVideo.url ? <GraphicVideo src={graphicTwoVideo.url} playsInline autoPlay muted loop>
                    <source src={graphicTwoVideo.url} type="video/mp4" />
                  Your browser does not support the video tag.
                  </GraphicVideo> : graphicTwoImage.url ? <GraphicImage src={graphicTwoImage.url} alt={graphicTwoImage.alt} /> : null }
              </GraphicBox> : null}
            </>
          }) : null }
      </TopGrid>
    </>
  )
}

const SmoothieConcept = (props) => {
  const { children, data, noMargin } = props;
  const { width } = React.useContext(StoreContext);

  return (
    <Wrapper>
      <Section none={noMargin}>
        <ModuleDivider theme='light' noborder>{data?.title_header}</ModuleDivider>
        { data && width >= 1080 ? DesktopLayout(data) : data ? MobileLayout(data) : null}
      </Section>
    </Wrapper>
  )
}

export default SmoothieConcept;

export const SmoothieConceptOverlay = (props) => {
  const { conceptOpen, setConceptOpen } = React.useContext(StoreContext);

  const {
    prismicConcept: { data }
  } = useStaticQuery(graphql`
    query {
      prismicConcept {
        id
        data {
          main_copy
          title_header
          sub_copy
          method {
            title {
              richText
              html
            }
            image {
              alt
              url(imgixParams: {q: 80, width: 750})
            }
            video {
              url
            }
            content {
              richText
              html
            }
          }
        }
      }
    }
  `);

  return (
    <Transition in={conceptOpen} timeout={{
      enter: 0,
      exit: 200,
     }}>
      {state => { 
         if(state !== 'exited') {
          return (<Overlay state={state} onClose={() => setConceptOpen(false)} {...props}>
            <SmoothieConcept data={data} noMargin/>
          </Overlay>)
         }
       }}
     </Transition>
  )
}

export const SmoothConceptOpen = (props) => {
  const { setConceptOpen } = React.useContext(StoreContext);
  return (
    <LearnMoreWrapper>
      <LearnMore onClick={()=> { setConceptOpen(true) }}>
        How to enjoy
      </LearnMore>
    </LearnMoreWrapper>
  )
}