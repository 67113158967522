import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from "gatsby"
import { Transition } from 'react-transition-group';
import CartBar from './CartBar';
import CartEmpty from './CartEmpty';
import CartProducts from './CartProducts';
import CartMethods from './CartMethods';
import { StoreContext } from '../../context/store-context';
import { media } from '../../utils/mediaQuery';



const Wrapper = styled.div`
  position: fixed;
  height: calc(100% - 80px);
  top: 80px;
  z-index: 4;
  width: 100%;
  display: flex;
  flex-direction: column;

  ${media.large`
    padding-right: 26px;
    width:calc(100vw + 41px);
  `}
`

const CartBarWrapper = styled.div`
  transition: opacity 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  position: relative;
  z-index: 2;
  ${({state}) => state === 'entering' && `
    opacity: 0;
  `}

  ${({state}) => state === 'entered' && `
     opacity: 1;
  `}

  ${({state}) => state === 'exiting' && `
    opacity: 0;
  `}
`

const CartWrapper = styled.div`
  width: 100%;
  z-index: 1;
  flex: 1;
  position: relative;
  overflow-y: scroll;
  transition: opacity 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  ${({state}) => state === 'entering' && `
    opacity: 0;
  `}

  ${({state}) => state === 'entered' && `
     opacity: 1;
  `}

  ${({state}) => state === 'exiting' && `
    opacity: 0;
  `}
`
const BlurLayer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  left: 0;
  transition: all .2s cubic-bezier(0.215, 0.61, 0.355, 1);
  z-index: 0;

  ${({state}) => state === 'entering' && `
    background: rgba(242,234,222,0);
    backdrop-filter: blur(0px);
  `}

  ${({state}) => state === 'entered' && `
    background: rgba(242,234,222,0.1);
    backdrop-filter: blur(10px);
  `}

  ${({state}) => state === 'exiting' && `
    background: rgba(242,234,222,0);
    backdrop-filter: blur(0px);
  `}
`

const GreenLayer = styled.div`
  background: #383B23;
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  left: 0;
  z-index: 0;
`

const BeigeLayer = styled.div`
  background: #F2EADE;
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  left: 0;
  z-index: 0;
`
const Cart = () => {
  
  const {
    example: { priceRules, shippingZones }
  } = useStaticQuery(graphql`
    query {
      example {
        shippingZones {
          name
          price_based_shipping_rates {
            name
            price
            min_order_subtotal
          }
        }
        priceRules {
          node {
            status
            discountCodes {
              edges {
                node {
                  id
                  code
                }
              }
            }
            prerequisiteSubtotalRange {
              greaterThanOrEqualTo
            }
            valueV2 {
              amount
              percentage
            }
          }
        }
      }
} `);
  const { toggleCart, cartOpen, checkout, width } = React.useContext(StoreContext);
  const emptyCart = checkout.lineItems.length === 0

  const minimumRule = priceRules.reduce((acc,curr) => {
    const node = curr.node;
    if(node.status === 'ACTIVE' && node.discountCodes.edges[0].node.code === 'MINIMUMREACHED') {
      return node;
    }
  },[]);

  const domesticZone = shippingZones.reduce((acc,curr) => {
    if(curr.name === 'Domestic') {
      return curr;
    }
  },[]);
  const subtotalPrice = checkout.lineItemsSubtotalPrice ? checkout.lineItemsSubtotalPrice.amount : 0;
  const isThreshold = Number(subtotalPrice) >= 300  ? true : false;

  return (
    <Transition in={cartOpen} timeout={{
      enter: 0,
      exit: 200,
     }}>
      {state => {
        if(state !== 'exited') {
          return (
            <Wrapper>
              <CartBarWrapper state={state}>
              { width >= 1080 || !emptyCart ?
                  <CartBar minimum={minimumRule} domestic={domesticZone}/>
                : <CartEmpty /> }
              </CartBarWrapper> 
              
              {!emptyCart ? <CartWrapper state={state}>
                <CartProducts minimum={minimumRule} domestic={domesticZone} />
                <CartMethods />
                { isThreshold ? <BeigeLayer/> : <GreenLayer /> }
              </CartWrapper> : <BlurLayer state={state} onClick={toggleCart} />}
            </Wrapper>
          )
        }
    }}
    </Transition>
  )

}

export default Cart;