import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { StoreContext } from '../../context/store-context';
import Bracket from '../Bracket/Bracket';
import Section from '../Section/Section';
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';
import { CartRadio } from './CartComponents';
import { CartDate } from './CartDate';
import CallToAction from '../CallToAction/CallToAction';
import HtmlParser from '../HtmlParser/HtmlParser'
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';
import { addURLParams } from "../../utils/addURLParams";
import { linkhover } from '../../utils/lineHover';


const MainBracket = styled(Bracket)` 
  flex: 1;
`

const CartMethodsSection = styled(Section)`
  margin: 40px 0 0;

  ${media.large`
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-column-gap: 30px;
    margin: 40px 0 60px;
 `}
`

const InActive = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #F2EADE;
  opacity: ${props => props.active ? '0.7' : '0'};
  pointer-events: ${props => props.active ? 'auto' : 'none'};
  transition: opacity .2s ease;
  z-index: 2;
`

const CartMethodsCol = styled.div` 
  position: relative;
  margin: 0 0 20px;
  ${media.large`
    margin: 0;
 `}
`

const CartMethodsTimeslot = styled(CartMethodsCol)` 
  ${media.large`
    min-height: 450px;
 `}
`

const CartMethodsTimeslotInner = styled.div` 
  opacity: ${props => props.isLoading ? '0' : '1'};
  transition: opacity .2s ease;
`



const LoadingWrapper = styled.div` 
  position: absolute;
  width: 100%;
  height: 300px;
  left: 0;
  top:0;
  pointer-events: none;
`

const SectionTitle = styled.h2` 
  ${fontstack.default}
  ${type('m-body')}
  font-weight: 500;
  color: #383B23;
  margin: 0;
  padding: 0 0 20px;
  border-bottom: 1px solid #383B23;
  ${media.large`
    ${type('body')}
    font-weight: 500;
 `}
`

const CartThresholdStatement = styled.div` 
  ${type('m-large')}
  font-weight: 300;
  color: #F2EADE;
  ${media.large`
    ${type('large')}
    font-weight: 300;
 `}
`

const CartThresholdStatementEmoji = styled(CartThresholdStatement)`
  display: flex;
  align-items: center;

  &:after {
    
    width: calc(100% + 4px);
    height: 1px;
    background: #F2EADE;
    display: block;
    flex:1;
    position: relative;
    left: -4px;

    ${media.large`
      content: '';
    `}
  }
`

const CartDateWrapper = styled.div` 
  position: relative;
`

const OrderLink = styled(Link)`
  position: relative;
  ${linkhover('#F3EFD7',true,true)}
`

const CartDateInActive = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #F2EADE;
  opacity: ${props => props.active ? '0.7' : '0'};
  pointer-events: ${props => props.active ? 'auto' : 'none'};
  transition: opacity .2s ease;
  z-index: 2;
`

const HorRadioWrapper = styled.div` 
  position: relative;
  width: 100%;
  display: flex;

  ${media.large`
    padding: 0 100px 0 0;
 `}
`

const PickupWrapper = styled.div` 
  position: relative;
  width: 100%;
  border-top: 1px solid #383B23;

  ${media.large`
    padding: 0 100px 0 0;
 `}
`

const PickupRadio = styled(CartRadio)`
  border-bottom: 1px solid #383B23;
`

const BagCopy = styled.p` 
  ${fontstack.default}
  ${type('m-body')} 
  color: #383B23;
  margin: 20px 0;
  width: 100%;
  ${media.large`
    ${type('body')}
    width: 50%;
    margin: ${props => props.top ? '20px 0' : '0 0 20px'};
 `}
`

const CheckoutButton = styled(CallToAction)`
  font-weight: 500;
  width: 100%;
  text-transform: uppercase;
`

const CheckoutButtonWrapper = styled.div`
  outline: none;
  border: none;
  background: transparent;
  width: 100%;

  &:disabled {
    opacity: 0.7;
  }
`


const CartMethods = () => {
  const { getLocations, getLocationsCalendar, checkout, toggleCart, loading, addMethodToCheckout, updateLineItemsWithZapietId, setBag } = React.useContext(StoreContext)
  const [ Method, setMethod] = useState();
  const needsBag = useRef(false);
  const chosenDate = useRef(); 
  const [ Calendar, setCalendar ] = useState();
  const [ Locations, setLocations ] = useState();
  const [ Location, setLocation ] = useState();
  const [startDate, setStartDate] = useState(false);
  const [ isBag, setIsBag ] = useState(false);
  const [ isTimeSlotLoading, setIsTimeSlotLoading ] = useState(false);
  const [ DateInActive, setDateInActive] = useState();
  const [ ToShopify, setToShopify ] = useState(false);
  const LocationId = useRef();

  useEffect(() => {
    if(Method) {
      setIsTimeSlotLoading(true);
      getLocations(Method).then((data)=> {
        setLocations(data);
        if(Method === 'delivery') {
          const selectedLocation = data.locations ? data.locations[0] : data;
          LocationId.current = selectedLocation.id;
          setLocation(selectedLocation);
          return getLocationsCalendar(selectedLocation.id, Method);
        }
       
        return true

      }).then((data)=>{
        if(Method === 'delivery') {
          setCalendar(data);
        }
        setIsTimeSlotLoading(false);
        setStartDate(false);
      });
    }
  }, [Method])



  const methodChange = (method) => {
    setLocation(null);
    setMethod(method);
    setStartDate(false);
  }

  const bagChange = (value) => {
    needsBag.current = value;
    setIsBag(true);
    if(startDate) {
      setIsTimeSlotLoading(true);
      updateCheckout();
    }
    
  }


  const locationChange = (e) => {
    setStartDate(null);
    LocationId.current = e.target.value;
    const location = Locations.locations.filter((location) => {
      return location.id === Number(LocationId.current);
    })[0];
    setLocation(location);
    getLocationsCalendar(e.target.value, Method).then((data)=>{
      setCalendar(data);
    });
  }

  const updateCheckout = () => {
    const date = chosenDate.current;
    setDateInActive(true);
    
    if(date) {
      updateLineItemsWithZapietId(Method, LocationId.current).then(() => {
        
        return addMethodToCheckout(LocationId.current,date,Method, needsBag.current.toString())
      }).then(()=>{
        setDateInActive(false);
        setIsTimeSlotLoading(false);
        setStartDate(date)
      });
      
    }

  }

  const selectedDateTime = (date) => {
    chosenDate.current = date;
    updateCheckout();
  }

  const handleCheckout = () => {
    setToShopify(true);
    let params; 

    if(Method === 'pickup') {
      const location = Location;
      params = [
        {key: 'method', value: Method},
        {key: 'step', value: 'contact_information'},
        {key: 'locale', value: 'en-AU'},
        {key: 'checkout[shipping_address][last_name]', value: 'Nute'},
        {key: 'checkout[shipping_address][address1]', value: location.address_line_1},
        {key: 'checkout[shipping_address][address2]', value: ''},
        {key: 'checkout[shipping_address][city]', value: location.city},
        {key: 'checkout[shipping_address][country]', value: location.country},
        {key: 'checkout[shipping_address][province]', value: location.region},
        {key: 'checkout[shipping_address][company]', value: location.company_name},
      ]

    } else if (Method === 'delivery') {
      params = [
        {key: 'method', value: Method},
        {key: 'step', value: 'contact_information'},
        {key: 'locale', value: 'en-CA'}
      ]
    }
    
    let webUrl = addURLParams(checkout.webUrl, params);
    window.location.href = webUrl;
  }

  const filterOutDays = (date) => {
    const calendar = Calendar;
    const day = date.getDay();
    const minDate = new Date(calendar.minDate+'T00:00:00');
    const maxDate = new Date(calendar.maxDate+'T00:00:00');

    let isEnabled = true;


    calendar.disabled.forEach((disabledDay) => {
      if(disabledDay-1 === day)
        isEnabled = false;
    });

    if(date < minDate || date > maxDate) {
      console.log(date)
      isEnabled = false;
    }

    return isEnabled;
  }

  const subtotalPrice = checkout.lineItemsSubtotalPrice ? checkout.lineItemsSubtotalPrice.amount : 0;
  const isThreshold = Number(subtotalPrice) >= 300  ? true : false;

  return (
    <>
     { isThreshold ?
     <MainBracket bottom topShift zIndex={1} background='#F2EADE'>
     <CartMethodsSection>
       <InActive active={!isThreshold}/>
       <CartMethodsCol>
         <SectionTitle>Receipt Methods</SectionTitle>
         <HorRadioWrapper onChange={(e)=>methodChange(e.target.value)}>
           <CartRadio name="method" value="pickup">Pickup</CartRadio>
           <CartRadio name="method" value="delivery">Delivery</CartRadio>
         </HorRadioWrapper>
         { Method === 'pickup' ? <PickupWrapper onChange={locationChange}>
           { Locations && Locations.locations ? Locations.locations.map((location, index) => {
               return <PickupRadio name="pickup_location" value={location.id}>{location.company_name}<br/>{location.address_line_1}, {location.more_information}</PickupRadio>
             }) : null }
         </PickupWrapper> : null }
       </CartMethodsCol>
       <CartMethodsTimeslot>
         <LoadingWrapper>
           <LoadingAnimation isLoading={isTimeSlotLoading}  background={'#F2EADE'}/>
         </LoadingWrapper>
         <CartMethodsTimeslotInner isLoading={isTimeSlotLoading}>
         { Location  ?
         <>
         <SectionTitle>{ Method === 'pickup' ? 'Pickup Timeslot' : 'Delivery Timeslot' }</SectionTitle>
         { Method === 'pickup' && Location ?
         <HorRadioWrapper onChange={(e)=>bagChange(e.target.value)}>
           <CartRadio name="bag" value="false">No Bag</CartRadio>
           <CartRadio name="bag" value="true">Bag</CartRadio>
         </HorRadioWrapper> : null }
         <HorRadioWrapper>
             { Method === 'pickup' && Location ? <BagCopy><HtmlParser>{Location.custom_attribute_1}</HtmlParser></BagCopy> : Method === 'delivery' && Location ? <BagCopy top><HtmlParser>{Location.custom_attribute_2}</HtmlParser></BagCopy> : null}
         </HorRadioWrapper>
           { Method === 'pickup' && isBag || Method === 'delivery' ? <CartDateWrapper>
             <CartDateInActive active={DateInActive}/>
             <CartDate selected={startDate} onChange={(date) => selectedDateTime(date)} filterDate={Calendar ? filterOutDays : null}  isClearable/>
           </CartDateWrapper> : null }
           <CheckoutButtonWrapper>
             <CheckoutButton invert onClick={handleCheckout} isLoading={ToShopify} loadingBg='#383B23' loadingTheme="light" disabled={!startDate || DateInActive}>checkout</CheckoutButton> 
           </CheckoutButtonWrapper>
           </>: null }
           </CartMethodsTimeslotInner>
       </CartMethodsTimeslot>
     </CartMethodsSection>
   </MainBracket> : 
   <MainBracket topShift zIndex={1} background='#383B23'>
      <CartMethodsSection>
        <CartMethodsCol>
          <CartThresholdStatementEmoji>
              Oops 😅
          </CartThresholdStatementEmoji>
        </CartMethodsCol>
       <CartMethodsTimeslot>
         <CartThresholdStatement>
         <OrderLink to='/products' onClick={toggleCart}>Order</OrderLink> more nourishments<br/>to reach the HK$300 minimum.
         </CartThresholdStatement>
       </CartMethodsTimeslot>
      </CartMethodsSection>
    </MainBracket>
    }
    
    </>
    
  )
}

export default CartMethods;
