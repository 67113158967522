import React from 'react';
import styled from 'styled-components';
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';
import Arrow from './assets/arrow.svg';


const MiniArrow = styled(Arrow)`
  width: 13px;
  margin: 0 0 0 5px;
  stroke: ${props => props.theme === 'light' ? '#F2EADE': '#383B23'};
  stroke-width: 4px;
  transition: transform .2s ease;
  ${media.large`
    margin: 0 0 0 14px;
  `}
`


const MiniWrapper = styled.div` 
  ${fontstack.default}
  ${type('m-body')}
  position: relative;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  color: ${props => props.theme === 'light' ? '#F2EADE': '#383B23'};
  letter-spacing: 0.050em;
  cursor: pointer;

  ${media.large`
    ${type('body')}
    font-weight: 500;
  `}

  &:hover > ${MiniArrow} {
    transform: rotate(45deg);
  }
`


const AddMini = (props) => {
  const { children, theme, isLoading, ...rest } = props;
  return (
    <MiniWrapper theme={theme} {...rest}>
      <LoadingAnimation isLoading={isLoading} theme={theme} background={'#F9F9F9'}/>
      {children}
      <MiniArrow theme={theme} />
    </MiniWrapper>
  )
}

export { AddMini };

const LargeWrapper = styled.div` 
  ${fontstack.default}
  ${type('m-large')} 
  font-weight: 300;
  display: flex;
  align-items: center;
  color: ${props => props.theme === 'light' ? '#F2EADE': '#383B23'};
  letter-spacing: 0.030em;

  ${media.large`
    ${type('large')} 
    font-weight: 300;
  `}
`

const LargeArrow = styled(Arrow)`
  width: 20px;
  margin: 0 0 0 10px;
  stroke: ${props => props.theme === 'light' ? '#F2EADE': '#383B23'};
  stroke-width: 3px;

  ${media.large`
    margin: 0 0 0 50px;
    width: 46px;
  `}
`

const AddLarge = (props) => {
  const { children, theme } = props;
  return (
    <LargeWrapper theme={theme}>
      {children}
      <LargeArrow theme={theme} />
    </LargeWrapper>
  )
}

export { AddLarge };