import { css } from 'styled-components';

export const fontstack = {
  default: () =>
    css`
        font-family: 'Neue Haas Grotesk Display Pro';
    `,
  secondary: () =>
    css`
        font-family: 'Nib Pro';
    `
}
