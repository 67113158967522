import React from 'react';
import styled from 'styled-components';
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';

const Wrapper = styled.button`
  cursor: pointer;
  display: inline-block;
  position: relative;
  ${fontstack.default}
  color: ${props => props.invert ? '#F3EFD7' : props.theme === "dark" ? "#F3EFD7" : '#383B23'};
  ${type('m-body')}
  line-height: 1;
  padding: 20px 34px;
  border: 1px solid ${props => props.theme === "dark" ? "#F3EFD7" : '#383B23'};
  text-align: center;
  background: ${props => props.invert ? '#383B23' :'transparent'};
  transition: all .2s cubic-bezier(0.215, 0.61, 0.355, 1);
  box-sizing: border-box;

  &:hover:enabled {
    color: ${props => props.invert ? '#383B23' : props.theme === "dark" ? "#383B23" : '#F3EFD7'};
    background: ${props => props.invert ? 'transparent' : props.theme === "dark" ? "#F3EFD7" : '#383B23'};
  }

  &:disabled {
    opacity: 0.7;
  }

  ${media.large`
    ${type('body')}
  `}
`

const CallToAction = (props) => {
  const { children, invert, isLoading, theme, loadingTheme, loadingBg, ...rest } = props;

  return (
    <Wrapper invert={invert} theme={theme} {...rest}>
      <LoadingAnimation isLoading={isLoading} theme={loadingTheme ? loadingTheme : invert ? 'dark' : 'light'} background={loadingBg ? loadingBg : invert ? '#F3EFD7': '#383B23'} />
      {children}
    </Wrapper>
  )

}

export default CallToAction;