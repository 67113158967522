import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { useForm } from 'react-hook-form';
import EmailField, { EmailFieldSmall } from './EmailField';
import EmailLabel, { EmailLabelSmall } from './EmailLabel';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';

const FormWrapper = styled.form`
  position: relative;
  padding: 0 0 14px;
  border-bottom: 1px solid ${props => props.theme === 'light' ? '#F2EADE': '#383B23'};
`

const FormWrapperInner = styled.div`
  position: relative;
  display: flex;
  opacity: ${props => props.disable ? '0' : '1'};
  transition: opacity .2s ease;
`

const SubmitButton = styled.button`
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
`

const Prompt = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: ${props => props.theme === 'light' ? '#F2EADE': '#383B23'};

  ${fontstack.default}
  ${type('m-body')}
  ${media.large`
    ${type('body')}
  `}
`

const SmallFormWrapper = styled(FormWrapper)` 
  padding: 0 0 5.5px;
`

const EmailFooterForm = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    console.log(data);
  }

  return (
    <FormWrapper onSubmit={handleSubmit(onSubmit)}> 
      <FormWrapperInner>
        <EmailField type="text" placeholder="Email" {...register('email',{required: true, pattern: /^\S+@\S+$/i})} />
        <SubmitButton type="submit">
          <EmailLabel>Sign Up</EmailLabel>
        </SubmitButton>
      </FormWrapperInner>  
    </FormWrapper>
  )
}

export default EmailFooterForm


const EmailFooterFormSmall = ({theme}) => {
  const { register, handleSubmit, reset, formState } = useForm();
  const [ promptActive, setPromptActive ] = useState(false);
  const mailchimpMsg = useRef();

  const { errors } = formState;

  useEffect(() => {
    
  }, [promptActive])

  const onSubmit = (data) => {
    addToMailchimp(data.email) // listFields are optional if you are only capturing the email address.
    .then(data => {
      firePrompt('We look forward to nourishing you.')
    }).catch(() => {
      firePrompt('Error. Please try again.')
    });
  }

  const firePrompt = (msg) => {
      mailchimpMsg.current = msg;
      setPromptActive(true);

      setTimeout(() => {
        setPromptActive(false);
        reset();

      }, 4000);

  }

  return (
    <SmallFormWrapper theme={theme} onSubmit={handleSubmit(onSubmit)}>
      <FormWrapperInner disable={promptActive}>
        <EmailFieldSmall theme={theme} type="text" placeholder="Email" {...register('email',{required: 'Enter an email', pattern: /^\S+@\S+$/i})} />
        <SubmitButton type="submit">
          <EmailLabelSmall theme={theme}>Subscribe</EmailLabelSmall>
        </SubmitButton>
      </FormWrapperInner>    
      {promptActive ? <Prompt theme={theme}>
        <p>{mailchimpMsg.current}</p>
      </Prompt> : null }
      
    </SmallFormWrapper>
  )
}

export { EmailFooterFormSmall }