import React, { useEffect } from 'react';
import styled from 'styled-components';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';
import CloseIcon from './assets/close.svg';

const Wrapper = styled.div`
  position: fixed;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  padding: 0 20px;
  ${media.large`
    padding: 0 30px;
  `}
`

const BlurLayer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all .2s ease;
  z-index: 0;

  ${({state}) => state === 'entering' && `
    background: rgba(242,234,222,0);
    backdrop-filter: blur(0px);
  `}

  ${({state}) => state === 'entered' && `
    background: rgba(242,234,222,0.1);
    backdrop-filter: blur(10px);
  `}

  ${({state}) => state === 'exiting' && `
    background: rgba(242,234,222,0);
    backdrop-filter: blur(0px);
  `}
`

const Box = styled.div`
  position: relative;
  background: #F2EADE;
  padding: 15px 17px 30px;
  border-radius: 10px;
  width: 530px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  transition: opacity .2s ease;

  ${({state}) => state === 'entering' && `
    opacity: 0;
  `}

  ${({state}) => state === 'entered' && `
    opacity: 1;
  `}

  ${({state,pos}) => state === 'exiting' && `
    opacity: 0;
  `}

  ${media.large`
    padding: 18px 30px 15px;
  `}
`

const Close = styled(CloseIcon)`
  position: absolute;
  width: 15px;
  right: 30px;
  cursor: pointer;
`

const Title = styled.h2`
  ${fontstack.default}
  ${type('m-body')}
  text-align: center;
  margin: 0 0 20px;
  color: #383B23;

  ${media.large`
    ${type('body')}
  `}
`

const Statement = styled.h3`
  ${fontstack.default}
  ${type('m-large')}
  font-weight: 300;
  margin: 30px 0 30px;
  color: #383B23;
  letter-spacing: 0.030em;

  ${media.large`
    ${type('large')}
    font-weight: 300;
  `}
`

const Modal = (props) => {
  const { children, title, statement, onClose, state, ...rest} = props;
  
  return (
    <Wrapper>
      <Box state={state} {...rest}>
        <Close onClick={onClose || null} />
        { title ? <Title>{title}</Title> : null }
        { statement ? <Statement>{statement}</Statement> : null }
        { children }
      </Box>
      <BlurLayer state={state} onClick={onClose}/>
    </Wrapper>
  )
}

export default Modal;