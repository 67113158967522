import React from 'react';
import styled from 'styled-components';
import { Link } from "gatsby"
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';

const Wrapper = styled(Link)`
  position: relative;
  color: #F2EADE; 
  ${fontstack.default}
  display: inline-block;
  ${type('m-body')}

  ${media.large`
    ${type('body')}
  `}
`

const AnchorWrapper = styled.a`
  position: relative;
  color: #F2EADE; 
  ${fontstack.default}
  display: inline-block;
  ${type('m-body')}

  ${media.large`
    ${type('body')}
  `}  
`
const SideMenuTertiaryItem = (props) => {
  const { children, href, ...rest } = props;

  return (
    <>
    {href ?
      <AnchorWrapper href={href} {...rest}>
      { children }
      </AnchorWrapper>
    : <Wrapper {...rest}>
        { children }
      </Wrapper>}
    </>
  )
}

export default SideMenuTertiaryItem;