import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { navigate } from 'gatsby';
import Modal from '../Modal/Modal';
import { StoreContext } from "../../context/store-context";
import { Transition } from 'react-transition-group';
import CallToAction from '../CallToAction/CallToAction';
import { TextField, Errors } from '../Forms/Forms';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';

const Wrapper = styled(Modal)`
  max-width: 600px;
  width: 100%;
`

const SignIn = styled(CallToAction)`
  width: 100%;
  max-width: initial;

  ${media.large`
    max-width: 284px;
  `}
`

const FormWrapper = styled.form` 
  position: relative;
`

const BottomWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0 0;

  ${media.large`
    margin: 50px 0 0;
  `}
`

const Link = styled.a`
  ${fontstack.default}
  ${type('caption')}
  color: #383B23;
  margin: 0 20px;
  cursor: pointer;

  ${media.large`
    ${type('body')}
  `}
`

const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0 0;

  ${media.large`
    margin: 50px 0 35px;
  `}
`

const AccountRecover = ({ state, callback }) => {
  const { toggleLogin, customerRecover} = React.useContext(StoreContext);
  const { register, handleSubmit } = useForm();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ errors, setErrors ] = useState();

  const onSubmit = (data) => {
    setErrors(false);
    setIsLoading(true);
    customerRecover(data.email).then((response)=>{
      setIsLoading(false);
      const customerRecover = response.data.customerRecover;
      const errors = response.errors;
      if(customerRecover && customerRecover.customerUserErrors.length === 0) {
        //success 
        toggleLogin();
      } else {
        if(customerRecover && customerRecover.customerUserErrors) {
          setErrors(customerRecover.customerUserErrors);
        } else if (errors) {
          setErrors(errors);
        }
        
        console.log('error')
      }
    })
  }

  return (
    <Wrapper title='Reset your password' statement='We will send you an email to reset your password' onClose={()=>toggleLogin()} state={state}>
      { errors ? <Errors errors={errors} /> : null }
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <TextField type="text" placeholder="Email" withBorder {...register("email", {required: true, pattern: /^\S+@\S+$/i})}/>
        <BottomWrapper>
          <SignIn isLoading={isLoading}>Submit</SignIn>
          <LinkWrapper>
            <Link onClick={()=>callback('login')}>Cancel</Link>
          </LinkWrapper>
        </BottomWrapper>
      </FormWrapper>
    </Wrapper>
  )
}

export default AccountRecover;