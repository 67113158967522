import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';
import { StoreContext } from '../../context/store-context';
import CloseSvg from './assets/close.svg';

const Wrapper = styled.div` 
  background: #383B23;
  border: none;
  outline: none;
  height: calc(100vh - 80px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  z-index: 2;
  position: relative;
  overflow: hidden;
`

const EmptyMessage = styled.p` 
  ${fontstack.default}
  ${type('m-body')}
  color: #F2EADE;
  margin: 0;
  text-align: center;
  ${media.large`
    ${type('body')}
  `}
`

const CloseIcon = styled(CloseSvg)`
  position: absolute;
  right: 20px;
  top: 40px;
  stroke: #f3efd7;
  cursor: pointer;
  z-index: 10;
`

const CustomLink = styled(Link)` 
  padding: 0 0 2.5px;
  border-bottom: 1px solid #F2EADE; 
`

const CartEmpty = () => {

  const { toggleCart } = React.useContext(StoreContext);
  return (
    <Wrapper>
      <CloseIcon onClick={toggleCart}/>
      <EmptyMessage>No goodies yet.<br/><CustomLink to="/products" onClick={toggleCart}>Start nourishing.</CustomLink></EmptyMessage>
    </Wrapper>
  )
}

export default CartEmpty;