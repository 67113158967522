import React from 'react';
import styled from 'styled-components';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';

const Input = styled.input` 
  ${fontstack.default}
  ${type('large')}
  font-weight: 300;
  color: ${props => props.theme === 'light' ? '#F2EADE': '#383B23'};
  border: none;
  flex: 1;
  outline: none;
  background: transparent;

  &::placeholder {
    color: ${props => props.theme === 'light' ? '#F2EADE': '#383B23'};
    opacity: 0.2;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover, 
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    background-color: transparent !important;
    background-clip: content-box !important;
  }
`

const EmailField = React.forwardRef((props, ref) => {

  return (
    <Input ref={ref} {...props}/>
  )
});

export default EmailField;



const InputSmall = styled(Input)` 
  ${type('m-body')}
  font-weight: 400;
  background: transparent;
  ${media.large`
    ${type('body')}
    font-weight: 400;
  `}
`

const EmailFieldSmall = React.forwardRef((props, ref) => {

  return (
    <InputSmall ref={ref} {...props}/>
  )
});

export { EmailFieldSmall };