import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { navigate } from 'gatsby';
import Modal from '../Modal/Modal';
import { StoreContext } from "../../context/store-context";
import { Transition } from 'react-transition-group';
import CallToAction from '../CallToAction/CallToAction';
import { TextField, Errors } from '../Forms/Forms';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';

const Wrapper = styled(Modal)`
  max-width: 600px;
  width: 100%;
`

const SignIn = styled(CallToAction)`
  width: 100%;
  max-width: initial;

  ${media.large`
    max-width: 284px;
  `}
`

const FormWrapper = styled.form` 
  position: relative;
`

const SubmitButton = styled.button`
  outline: none;
  border: none;
  background: transparent;
  width: 100%;
`

const BottomWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0 0;

  ${media.large`
    margin: 50px 0 0;
  `}
`

const Link = styled.a`
  ${fontstack.default}
  ${type('caption')}
  color: #383B23;
  margin: 0 20px;
  cursor: pointer;

  ${media.large`
    ${type('body')}
  `}
`

const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0 0;

  ${media.large`
    margin: 50px 0 35px;
  `}
`

const AccountLogin = ({ state, callback }) => {
  const { toggleLogin, loginOpen, login} = React.useContext(StoreContext);
  const { register, handleSubmit } = useForm();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ errors, setErrors ] = useState();

  const onSubmit = (data) => {
    setErrors(false);
    setIsLoading(true);
    login(data).then((response)=>{
      
      if(response.customerAccessToken) {
        //success
        toggleLogin();
        navigate("/account")
      } else {
        setIsLoading(false);
        setErrors(response.customerUserErrors);
        console.log('error')
      }
    })
  }

  return (
    <Wrapper title='Your Account' statement='Hello,
    it’s nüte to see you' onClose={()=>toggleLogin()} state={state}>
      { errors ? <Errors errors={errors} /> : null }
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <TextField type="text" placeholder="Email" withBorder {...register("email", {required: true, pattern: /^\S+@\S+$/i})}/>
        <TextField type="password" placeholder="Password" withBorder {...register("password")}/>
        <BottomWrapper>
          <SubmitButton><SignIn isLoading={isLoading}>Sign In</SignIn></SubmitButton>
          <LinkWrapper>
            <Link onClick={()=>callback('create')}>Create account</Link>
            <Link onClick={()=>callback('recover')}>Forgot my password</Link>
          </LinkWrapper>
        </BottomWrapper>
      </FormWrapper>
    </Wrapper>
  )
}

export default AccountLogin;