import React from 'react';
import styled from 'styled-components';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import Arrow from './assets/arrow.svg';

const Wrapper = styled.div` 
  ${fontstack.default}
  ${type('body')}
  text-transform: uppercase;
  color: #383B23;
  border: none;
  outline: none;
  display: flex;
  align-items:center;
`

const Icon = styled(Arrow)`
  width: 20px;
  margin: 0 15px 0 0;
  display: inline-block;
`

const EmailLabel = (props) => {
  const { children } = props;
  return (
    <Wrapper><Icon /><div>{children}</div></Wrapper>
  )
}

export default EmailLabel;