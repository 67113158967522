import React, { useRef } from 'react';
import styled, { keyframes } from 'styled-components';


const ticker = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
`

const Wrapper = styled.div`
  width: 100%;
  overflow-x: hidden;
  position: relative;
`

const Inner = styled.div`
  box-sizing: border-box;
  padding: ${props => props.nopad ? '0px' : '20px 0'};
  position: relative;
  display: inline-flex;
  animation-name: ${ticker};
  animation-duration: ${props => props.speed ? props.speed : '50s'};
  backface-visibility: hidden;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
`

const Set = styled.div`
  display: inline-flex;
`

 const Item = styled.div`
  margin: 0 10px;
`


const CSSNewsTicker = (props) => {
  const { children, speed, nopad, ...rest } = props;
  const AnimatedEl = useRef();

  const onMouseEnter = () => {
    AnimatedEl.current.style.animationPlayState = 'paused';
  }

  const onMouseLeave = () => {
    AnimatedEl.current.style.animationPlayState = 'running';
  }

  const items = React.Children.map(children, (child, i) => {
    return <Item>{child}</Item>;
  });


  return (
    <Wrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} {...rest}>
      <Inner speed={speed} ref={AnimatedEl} nopad={nopad}>
        <Set>{items}</Set>
        <Set>{items}</Set>
        <Set>{items}</Set>
      </Inner>
    </Wrapper>
  )

}

export default CSSNewsTicker;