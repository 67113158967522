import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import MinusIcon from './assets/minus.svg';
import AddIcon from './assets/plus.svg';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';

const Input = styled.input` 
  ${fontstack.default}
  ${type('m-body')}
  color: #383B23;
  border: none;
  width: 100%;
  flex: 1;
  outline: none;
  display: block;
  background: transparent;
  padding: 5px 0;
  border-radius: 0;

  &:not(:last-of-type) {
    margin: 0 0 20px;
  }
  
  ${({ border }) => border && `
    border-bottom: 1px solid #383B23;
  `}

  ${media.large`
    ${type('body')}
    padding: 10px 0;

    &:not(:last-of-type) {
      margin: 0 0 40px;
    }
  
  `}
`

const TextField = React.forwardRef((props, ref) => {
  const { withBorder } = props;

  return (
    <Input border={withBorder} ref={ref} {...props}/>
  )
});

export { TextField };



const QuantityWrapper = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;

  ${({border}) => border && ` 
    padding: 20px;
    border: 1px solid #383B23;
    border-top: none;
  `}

  ${({fullBorder}) => fullBorder && ` 
    padding: 20px;
    border: 1px solid #383B23;
  `}
`

const QuantityVerticalWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const QuantityInput = styled.input`
  ${fontstack.default}
  ${type('m-body')}
  color: ${props => props.theme === 'light' ? '#F2EADE': '#383B23'};
  text-align: center;
  padding: 0;
  outline: none;
  border: none;
  background: transparent;
  flex: 1;

  ${media.large`
    ${type('body')}
  `}
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }

`

const IncrementButtonWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 12px 0 0;
`

const IncrementButton = styled.button`
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: ${props => props.circle ? props.theme === 'light' ? '1px solid #F2EADE' : '1px solid #383B23' : 'none'};
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 4px;
  transition: opacity .2s ease;
  opacity: ${props => props.active ? '1' : '0'};
`

const IncrementButtonVertical = styled(IncrementButton)` 
  height: 30px;
  width: 30px;
  padding: 8px;
`

const Minus = styled(MinusIcon)`
  stroke: ${props => props.theme === 'light' ? '#F2EADE': '#383B23'};
  width: 100%;
`
const Add = styled(AddIcon)`
  stroke: ${props => props.theme === 'light' ? '#F2EADE': '#383B23'};
  width: 100%;
`

const Quantity =  React.forwardRef((props, ref) =>{
  const { theme, circle, activeButtons, value, callback, vertical, border, fullBorder, max, step, ...rest } = props;
  const input = useRef();
  const cachedValue = useRef(value || 0)
  const [ Value, setValue ] = useState(value || 0);

  useEffect(()=>{
    setValue(value);
  },[value]);

  const onNumberChange = (val) => {
    if(max && Number(val) <= max) {
      setValue(val);
    }
  }

  const cacheValue = (val) => {
    if(val) {
      cachedValue.current = val;
    }
  }

  const onFocus = () => {
    input.current.select();
  }

  const onBlur = () => {
    const isDivisble = Value % step;
    
    if(Value && isDivisble === 0) {
      setValue(Value);
      cacheValue(Value);
      if(callback) {
        callback(Value)
      }
    } else {
      setValue(cachedValue.current);
      if(callback) {
        callback(cachedValue.current)
      }
    }
    
  }

  const onChange = (e) => {
     onNumberChange(e.target.value)
  }

  const stepUp = () => {
    input.current.stepUp();
    onNumberChange(input.current.value);
    cacheValue(input.current.value);
    if(callback) 
    {
        callback(input.current.value)
    }
  }

  const stepDown = () => {
    input.current.stepDown();
    onNumberChange(input.current.value);
    cacheValue(input.current.value);
    if(callback) 
    {
        callback(input.current.value)
    }
  }

  return (
    <>
      { vertical ? 
      <QuantityVerticalWrapper>
        <QuantityInput onChange={(e) => onChange(e)} ref={input} type='number' value={Value} theme={theme} onBlur={onBlur} onFocus={onFocus} step={step} max={max} {...rest}/>
        <IncrementButtonWrapper>
          <IncrementButtonVertical type='button' onClick={stepDown} circle={circle} theme={theme} active={activeButtons}><Minus theme={theme}/></IncrementButtonVertical>
          <IncrementButtonVertical type='button' onClick={stepUp} circle={circle} theme={theme} active={activeButtons}><Add theme={theme}/></IncrementButtonVertical>
        </IncrementButtonWrapper>
      </QuantityVerticalWrapper> :
      <QuantityWrapper border={border} fullBorder={fullBorder}>
        <IncrementButton type='button' onClick={stepDown} circle={circle} theme={theme} active={activeButtons}><Minus theme={theme}/></IncrementButton>
          <QuantityInput onChange={(e) => onChange(e)} ref={input} type='number' value={Value} theme={theme} onBlur={onBlur} onFocus={onFocus} step={step} max={max} {...rest}/>
        <IncrementButton type='button' onClick={stepUp} circle={circle} theme={theme} active={activeButtons}><Add theme={theme}/></IncrementButton>
      </QuantityWrapper>  }
    </>
  
  )
});

export { Quantity }

const ErrorsList = styled.ul` 
  margin: 0 0 30px 12px;
  padding: 0;
`

const ErrorMessage = styled.li`
  ${fontstack.default}
  ${type('m-body')}
  color: #383B23;
  padding: 0;

  ${media.large`
    ${type('body')}
  `}
`
const Errors = ({errors}) => {

  return (
    <ErrorsList>
      {errors && errors.map((error)=> {
       return <ErrorMessage>{error.message}</ErrorMessage>
      })}
    </ErrorsList>
  )
}

export { Errors }