import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import slugify from "@sindresorhus/slugify"
import { graphql, useStaticQuery, Link } from 'gatsby';
import { Transition } from 'react-transition-group';
import { StoreContext } from "../../context/store-context";
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { linkhover } from '../../utils/lineHover';
import { getMetafields } from '../../utils/getMetafields';
import { EmailFooterFormSmall } from '../EmailFooterForm/EmailFooterForm';
import SideMenuPanel from './SideMenuPanel';
import SideMenuItem, { SideMenuItemGroup, SideMenuItemWrapper } from './SideMenuItem';
import { ArrowLink } from '../ArrowLink/ArrowLink';
import { NavItem } from '../Navigation/Navigation';
import SideMenuTertiaryItem from './SideMenuTertiaryItem';
import CloseButton from './assets/close.svg';


const Base = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 100;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`

const BlurLayer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all .2s ease;
  z-index: 0;

  ${({state}) => state === 'entering' && `
    background: rgba(242,234,222,0);
    backdrop-filter: blur(0px);
  `}

  ${({state}) => state === 'entered' && `
    background: rgba(242,234,222,0.1);
    backdrop-filter: blur(10px);
  `}

  ${({state}) => state === 'exiting' && `
    background: rgba(242,234,222,0);
    backdrop-filter: blur(0px);
  `}
`

const TierOne = styled.div`
  position: relative;
  transition: ${props => props.duration ? `transform ${props.duration}ms cubic-bezier(0.215, 0.61, 0.355, 1)` : 'transform .2s cubic-bezier(0.215, 0.61, 0.355, 1)'};
  z-index: 2;
  pointer-events: all;
  ${({state}) => state === 'entering' && `
    transform: translate(-100%,0);
  `}

  ${({state}) => state === 'entered' && `
    transform: translate(0%,0);
  `}

  ${({state,pos}) => state === 'exiting' && `
    transform: translate(${pos}%,0);
  `}
`

const TierTwo = styled.div`
  position: relative;
  transition: ${props => props.duration ? `transform ${props.duration}ms cubic-bezier(0.215, 0.61, 0.355, 1)` : 'transform .2s cubic-bezier(0.215, 0.61, 0.355, 1)'};
  transform: translate(-100%,0);
  border-left: 1px solid #F3EFD7;
  z-index: 1;
  ${({state}) => state === 'entering' && `
    transform: translate(-100%,0);
  `}

  ${({state}) => state === 'entered' && `
    transform: translate(0%,0);
  `}

  ${({state,pos}) => state === 'exiting' && `
    transform: translate(${pos}%,0);
  `}
`

const Main = styled.div`
  flex: 1;
  margin: 180px 0 0;
  position: relative;
`

const Head = styled.div`
  position: relative;
  margin: 0 0 50px;
  height: 36px;
`

const FirstPanelMain = styled.div`
  position: absolute;
  width: 100%;
  top: 0; 
  left: 0;
  z-index: ${props => props.active ? '1' : '0'};
  opacity: ${props => props.active ? '1' : '0'};
  transition: opacity .2s ease;
`

const TopBar = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: ${props => props.align === 'right' ? 'flex-end' : 'flex-start'};
`

const TertiaryContainer = styled.div`
  display: grid;
  position: relative;
  width: 80%;
`

const MenuHead = styled(SideMenuTertiaryItem)` 
  color: #F3EFD7;
  margin: 0 0 20px;
  opacity: 0.5;
  text-transform: uppercase;
`

const MenuItem = styled(SideMenuTertiaryItem)` 
  color: #F3EFD7;
  margin: 0 0 5px;
  ${linkhover('#F3EFD7')}
`

const ArrowLinkMod = styled(ArrowLink)` 
  position: relative;
  ${linkhover('#F3EFD7')}
`

const MenuItemWrapper = styled.div`
  position: relative;
`

const FormWrapper = styled.div` 
  margin: 80px 0 0;
`

const PlainLabel = styled.div`
  ${fontstack.default}
  ${type('medium')}
  font-weight: 300;
  color: #F3EFD7;
`

const SideMenu = () => {
  const { toggleMenu, menuOpen, toggleTime } = React.useContext(StoreContext);
  const [ tierOpen, setTierOpen ] = useState(false); 
  const Timer = useRef(null);
  const initMenu = useRef(null);
  const tierPosition = useRef(-100);
  const tierOneExit = useRef(-100);
  const [ TierTwoPanel, setTierTwoPanel ] = useState(null);
  const tierExit = useRef(200);
  const tierOneSquared = useRef(false); 

  const {
    allShopifyCollection: { edges },
    prismicNavigation: { data }
  } = useStaticQuery(graphql`
    query {
      allShopifyCollection(sort: {order: DESC, fields: handle}) {
        edges {
          node {
            title
            handle
            productsCount
            products {
              id
              title
              ...ProductCard
              metafields {
                key
                value
              }
            }
          }
        }
      }
      prismicNavigation {
        data {
          body {
            ... on PrismicNavigationDataBodyCollectionItem {
              id
              primary {
                handle
                title
              }
              items {
                product {
                  title
                  handle
                  product_type
                }
                tag_label
              }
            }
          }
        }
      }
    }
  `);

  const navigationItems = data.body;
  useEffect(() => {

    if(typeof menuOpen === 'string' || menuOpen instanceof String){
      initMenu.current = menuOpen;
    }

    if(!menuOpen && tierOpen) {
      tierExit.current = 200;
      tierPosition.current = -200;
      tierOneExit.current = -200;
      setTierOpen(false);
    }

    if(menuOpen) {
      tierOneExit.current = -100;
      tierOneSquared.current = false;
    }

  }, [menuOpen])

  const openTier = (tier) => {
    tierOneSquared.current = true;
    const collectionObject = navigationItems.filter(collection => collection.primary.title === tier)[0];
    setTierTwoPanel(<ProductTypePanel collection={collectionObject} closeEvent={closeTier}/>)
    setTierOpen(true);
  }

  const closeTier = () => {
    if(menuOpen) {
      tierOneSquared.current = false;
    }
    setTierOpen(false);
  }
 
  return (
    <Transition in={menuOpen} timeout={{
      enter: 200,
      exit: 200,
     }}>
      {state => {
        if(state !== 'exited') {
          return (
            <Base>
              <TierOne state={state} duration={tierExit.current} pos={tierOneExit.current}><FirstPanel squared={tierOneSquared.current} init={initMenu.current} data={navigationItems} collections={edges} navEvent={openTier} closeEvent={closeTier}/></TierOne>  
              <Transition in={tierOpen} timeout={{
                enter: 0,
                exit: 200,
              }}>
                { state => {
                  if(state !== 'exited') {
                    return ( 
                      <TierTwo state={state} duration={tierExit.current} pos={tierPosition.current}>{TierTwoPanel}</TierTwo>
                    )
                  }
                }}
              </Transition>        
              <BlurLayer state={state} onMouseOver={()=>  {
                if(state==='entered') { 
                  const elapsedTime = new Date() - toggleTime.current;
                  if(elapsedTime > 200) {
                    toggleMenu();
                  }
                  
                } 
                }
                }/>
            </Base>
          )
        }
        
    }}
    </Transition>
  )
}

export default SideMenu;


const ShopMain = ({navEvent,collections,data,active}) => {
  const { toggleMenu, menuOpen, toggleCart, cartOpen } = React.useContext(StoreContext);
  return (
    <FirstPanelMain active={active}>
      <Head>
        <Link to='/products' onClick={()=>{toggleMenu(); if(cartOpen) { toggleCart(); } }}><ArrowLinkMod theme='light'>Shop all</ArrowLinkMod></Link>
      </Head>
      <SideMenuItemGroup>
        { data && data.map((item,i) => {
          return <SideMenuItemWrapper><SideMenuItem sup={item.items.length} key={i} onClick={()=> { navEvent(item?.primary?.title) }}>{item?.primary?.title}</SideMenuItem></SideMenuItemWrapper>
        }) }
      </SideMenuItemGroup>
    </FirstPanelMain>
  )
}

const DigestMain = ({active}) => {
  const { toggleMenu, toggleCart, cartOpen } = React.useContext(StoreContext);
  return (
    <FirstPanelMain active={active}>
      <Head>
      </Head>
      <SideMenuItemGroup>
         <SideMenuItemWrapper><SideMenuItem key="We"><Link to="/we" onClick={()=>{ toggleMenu(); if(cartOpen) { toggleCart(); } }}>We</Link></SideMenuItem></SideMenuItemWrapper>
         <SideMenuItemWrapper><SideMenuItem key="Partner"><Link to="/partner" onClick={()=>{toggleMenu(); if(cartOpen) { toggleCart(); } }}>Partner</Link></SideMenuItem></SideMenuItemWrapper>
         <SideMenuItemWrapper><SideMenuItem key="FAQs"><Link to="/faq" onClick={()=>{toggleMenu(); if(cartOpen) { toggleCart(); } }}>FAQs</Link></SideMenuItem></SideMenuItemWrapper>
      </SideMenuItemGroup>
    </FirstPanelMain>
  )
}

const FirstPanel = (props) => {
  const { navEvent, closeEvent, squared, collections, data, init} = props;
  const [ Active, setActive ] = useState(null);

  useEffect(() => {
    setActive(init || 'shop');
  }, []);
  

  return (
    <SideMenuPanel squared={squared}>
      <TopBar>
        <NavItem theme="light" key={0} onMouseOver={()=>setActive('shop')} active={Active==='shop' ? true : false}>Shop</NavItem>
        <NavItem theme="light" key={1} onMouseOver={()=>{setActive('digest'); closeEvent();}} active={Active==='digest' ? true : false}>Digest</NavItem>
      </TopBar>
      <Main>
        <ShopMain navEvent={navEvent} data={data} collections={collections} active={Active === 'shop' ? true : false} />
        <DigestMain active={Active === 'digest' ? true : false}/>
      </Main>
      { /*<TertiaryContainer>
        <MenuHead>Connect</MenuHead>
        <MenuItemWrapper><MenuItem target="__blank" href="https://wa.me/85260362938">Chat with us</MenuItem></MenuItemWrapper>
        <MenuItemWrapper><MenuItem href="mailto:hello@nutefoods.com">Email</MenuItem></MenuItemWrapper>
        <MenuItemWrapper><MenuItem target="__blank" href="http://instagram.com/nutefoods/">Instagram</MenuItem></MenuItemWrapper>
        <FormWrapper>
          <EmailFooterFormSmall theme="light"/>
        </FormWrapper>
  </TertiaryContainer> */}
    </SideMenuPanel>
  )
}


const CloseWrapper = styled.div`
  cursor: pointer;
  z-index: 3;
  position: relative;
`

const CollectionTitle = styled.h3` 
  ${fontstack.default}
  ${type('body')}
  font-weight: 500;
  position: absolute;
  color: #F3EFD7;
  text-transform: uppercase;
  top: -100%;
  height: 100%;
  display: flex;
  align-items: center;
`

const ProductTypePanel = (props) => {
  const { closeEvent, collection } = props;
  const { toggleMenu, toggleCart, cartOpen } = React.useContext(StoreContext);
  const products = collection.items;

  return (
    <SideMenuPanel>
      <TopBar align='right'>
          <CloseWrapper onClick={closeEvent}><CloseButton /></CloseWrapper>
      </TopBar>
      <Main>
        <Head>
          <CollectionTitle>{collection.primary.title}</CollectionTitle>
          <Link to={`/products/${collection.primary.handle}`} onClick={()=>{toggleMenu(); if(cartOpen) { toggleCart(); } }}>
            <ArrowLinkMod theme='light'>See All</ArrowLinkMod>
          </Link>
        </Head>
        <SideMenuItemGroup>
          { products ? products.map((product) => {
            const product_type_handle = product.product.product_type.toLowerCase().replace(/ /g,'-');
            const productLink = product_type_handle ? `/products/${product_type_handle}/${product.product.handle}` : `/products/${product.product.handle}`;
            return <SideMenuItemWrapper><Link to={productLink} onClick={()=>{toggleMenu(); if(cartOpen) { toggleCart(); } }}><SideMenuItem tag={product.tag_label ? product.tag_label : null}>{ product.product.title }</SideMenuItem></Link></SideMenuItemWrapper>
          }) : null }
        </SideMenuItemGroup>
        { collection.primary.handle === 'icebar-smoothies' ? 
        <>
        <SideMenuItemGroup>
          <PlainLabel>Or</PlainLabel>
        </SideMenuItemGroup>
        <SideMenuItemGroup>
          <Link to='/create-your-own' onClick={()=>{toggleMenu(); if(cartOpen) { toggleCart(); } }}><SideMenuItem>Create Your Own Set</SideMenuItem></Link>
        </SideMenuItemGroup>
        </>
         : null }
      </Main>
    </SideMenuPanel>
  )
}