import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.main`
  background: ${props => props.background ? props.background : '#F2EADE'};
  border-radius: ${props => props.squared ? '0' : '0 0 40px 40px'};
  position: relative;
  z-index: 1;
`
const Main = (props) => {
  const { children, squared, background } = props;

  return (
    <Wrapper squared={squared} background={background}>
      {children}
    </Wrapper>
    
  )
}

export default Main;