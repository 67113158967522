import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import slugify from "@sindresorhus/slugify"
import { graphql, useStaticQuery, Link } from 'gatsby';
import { Transition } from 'react-transition-group';
import { StoreContext } from "../../context/store-context";
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';
import { getMetafields } from '../../utils/getMetafields';
import { ArrowLinkSmall } from '../ArrowLink/ArrowLink';
import SideMenuItem, { SideMenuItemGroup, SideMenuItemWrapper } from './SideMenuItem';
import { EmailFooterFormSmall } from '../EmailFooterForm/EmailFooterForm';
import SideMenuTertiaryItem from './SideMenuTertiaryItem';
import { NavItemMobile } from '../Navigation/NavigationMobile';
import CloseButton from './assets/close.svg';

const Base = styled.div` 
  position: absolute;
  z-index: 1;
  left:0;
  top:0;
  width: 100%;
  background: #383B23;
  min-height: 100%;
  padding: 100px 20px 40px;
  box-sizing: border-box;
  opacity: 0;
  transition: opacity .2s ease;
  display: flex;
  flex-direction: column;


  ${({state}) => state === 'entering' && `
    opacity: 0;
  `}

  ${({state}) => state === 'entered' && `
    opacity: 1;
  `}

  ${({state,pos}) => state === 'exiting' && `
    opacity: 0;
  `}
`

const Header = styled.div` 
  margin: 0 0 60px;
`

const Content = styled.div` 
  position: relative;
  display: grid;
  flex: 1;
`

const Grouping = styled.div` 
  &:not(&:last-of-type) {
    margin: 0 0 40px;
  }
`

const GroupTitle = styled.h2`
  ${fontstack.default}
  ${type('m-body')}
  font-weight: 400;
  text-transform: uppercase;
  color: #F3EFD7;
  margin: 0;
  opacity: 0.5;
`

const TertiaryContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 60px 0 0;
`

const MenuHead = styled(GroupTitle)`
  margin: 0 0 20px;
`

const MenuItem = styled(SideMenuTertiaryItem)` 
  color: #F3EFD7;
  margin: 0 0 5px;
  display: block;
`

const FormWrapper = styled.div` 
  margin: 60px 0 0;
`


const PanelAnimate = styled.div`
  grid-area: 1 / 1;
  width: 100%;
  top: 0; 
  left: 0;
  transition: opacity .2s ease;
  z-index: 1;

  ${({state}) => state === 'entering' && `
    opacity: 0;
  `}

  ${({state}) => state === 'entered' && `
    opacity: 1;
  `}

  ${({state,pos}) => state === 'exiting' && `
    opacity: 0;
  `}
`

const SideMenuMobile = ({state}) => {
  const [ ProductPanel, setProductPanel ] = useState();
  const [ OpenProductPanel, setOpenProductPanel ] = useState();
  const { getCurrentUser, getCurrentUsername, toggleMobileMenu, toggleLogin, username } = React.useContext(StoreContext);
  const {
    allShopifyCollection: { edges },
    prismicNavigation: { data }
  } = useStaticQuery(graphql`
    query {
      allShopifyCollection(sort: {order: DESC, fields: handle}) {
        edges {
          node {
            title
            handle
            productsCount
            products {
              id
              title
              ...ProductCard
              metafields {
                key
                value
              }
            }
          }
        }
      }
      prismicNavigation {
        data {
          body {
            ... on PrismicNavigationDataBodyCollectionItem {
              id
              primary {
                handle
                title
              }
              items {
                product {
                  title
                  handle
                  product_type
                }
                tag_label
              }
            }
          }
        }
      }
    }
  `);

  const navigationItems = data.body;

  const openTier = (tier) => {
    const collectionObject = navigationItems.filter(collection => collection.primary.title === tier)[0];
    setProductPanel(<ProductTypePanel collection={collectionObject} closeEvent={closeTier}/>);
    setOpenProductPanel(true);
  }

  const closeTier = () => {
    setOpenProductPanel(false);
  }

  return (
    <Base state={state}>
      <Header>
        
      { getCurrentUser() && getCurrentUser().customerAccessToken.accessToken && username ?   
          <Link to="/account" onClick={()=>toggleMobileMenu()}><NavItemMobile theme="light">Hello, {username}</NavItemMobile></Link>
      : getCurrentUser() && getCurrentUser().customerAccessToken.accessToken ? <Link to="/account" onClick={()=>toggleMobileMenu()}><NavItemMobile theme="light">Account</NavItemMobile></Link>  : <NavItemMobile onClick={()=>{toggleMobileMenu(); toggleLogin();}} theme="light">Login</NavItemMobile>}
      </Header>
      <Content>
        <Transition in={!OpenProductPanel} timeout={{
          enter: 0,
          exit: 200,
        }}>
          { state => {
            if(state !== 'exited') {
              return ( 
                <PanelAnimate state={state}><MainPanel collections={edges} data={navigationItems} navEvent={openTier}/></PanelAnimate>
              )
            }
          }}
        </Transition> 
        <Transition in={OpenProductPanel} timeout={{
          enter: 0,
          exit: 200,
        }}>
          { state => {
            if(state !== 'exited') {
              return ( 
                <PanelAnimate state={state}>{ProductPanel}</PanelAnimate>
              )
            }
          }}
        </Transition> 
      </Content>
      <TertiaryContainer>
          <MenuHead>Connect</MenuHead>          
          <MenuItem target="__blank" href="https://wa.me/85260362938">Chat with us</MenuItem>
          <MenuItem href="mailto:hello@nutefoods.com">Email</MenuItem>
          <MenuItem href="instagram://user?username=nutefoods">Instagram</MenuItem>
          <FormWrapper>
            <EmailFooterFormSmall theme="light"/>
          </FormWrapper>
        </TertiaryContainer> 
    </Base>
  )
}

export default SideMenuMobile;


const SideMenuMobileItemWrapper = styled.button` 
  ${fontstack.default}
  ${type('m-large')}
  line-height: 28px;
  font-weight: 300;
  color: #F3EFD7;
  padding: 0;
  outline: none;
  background: transparent;
  border: 0;
  display: block;

  &:not(&:last-of-type) {
    margin: 0 0 5px;
  }
`

const SuperScript = styled.sup`
  font-size: 13px;
`

const  SideMenuMobileItem = ({children, sup, ...rest}) => {

  return (
    <SideMenuMobileItemWrapper {...rest}>
      {children}
      {sup ? <SuperScript>{sup}</SuperScript> : null}
    </SideMenuMobileItemWrapper>
  )
}


const MainPanel = ({collections, data, navEvent}) => {
  const { toggleMobileMenu } = React.useContext(StoreContext);
  return (
    <>
      <Grouping>
        <GroupTitle>Shop</GroupTitle>
        <SideMenuItemGroup>
          <SideMenuMobileItem><Link to='/products' onClick={()=>toggleMobileMenu()}>Shop All</Link></SideMenuMobileItem>
          { data.map((data,i) => {
            const collection = data;
            return <SideMenuMobileItem sup={collection.items.length} key={i} onClick={()=> { navEvent(collection.primary.title) }}>{collection.primary.title}</SideMenuMobileItem>
          }) }
        </SideMenuItemGroup>
      </Grouping>
      <Grouping>
        <GroupTitle>Digest</GroupTitle>
        <SideMenuItemGroup>
          <SideMenuMobileItem><Link to="/we" onClick={()=>toggleMobileMenu()}>We</Link></SideMenuMobileItem>
          <SideMenuMobileItem><Link to="/partner" onClick={()=>toggleMobileMenu()}>Partner</Link></SideMenuMobileItem>
          <SideMenuMobileItem><Link to="/faq" onClick={()=>toggleMobileMenu()}>FAQs</Link></SideMenuMobileItem>
        </SideMenuItemGroup>
      </Grouping>
    </>
  )
}

const CollectionTitle = styled.h3` 
  ${fontstack.default}
  ${type('caption')}
  font-weight: 500;
  color: #F3EFD7;
  text-transform: uppercase;
  margin: 0 0 5px;
`

const PlainLabel = styled.div`
  ${fontstack.default}
  ${type('m-body')}
  color: #F3EFD7;
`

const ProductTypePanel = (props) => {
  const { toggleMobileMenu } = React.useContext(StoreContext);
  const { closeEvent, collection } = props;
  const products = collection.items;
  return (
    <>
      <Grouping>
        <ArrowLinkSmall theme="light" reversed onClick={closeEvent}>SHOP</ArrowLinkSmall>
        <SideMenuItemGroup>
          <CollectionTitle>{collection.primary.title}</CollectionTitle>
          <Link to={`/products/${collection.primary.handle}`} onClick={()=>toggleMobileMenu()}>
            <ArrowLinkSmall theme="light">See All</ArrowLinkSmall>
          </Link>
        </SideMenuItemGroup>
        <SideMenuItemGroup>
        { products ? products.map((product) => {
          const product_type_handle = product.product.product_type.toLowerCase().replace(/ /g,'-');
          const productLink = product_type_handle ? `/products/${product_type_handle}/${product.product.handle}` : `/products/${product.product.handle}`;
          return <SideMenuMobileItem><Link to={productLink} onClick={()=>toggleMobileMenu()}>{ product.product.title }</Link></SideMenuMobileItem>
          }) : null }
        </SideMenuItemGroup>
      </Grouping>

      { collection && collection.primary.handle === 'icebar-smoothies' ? 
        <>
        <Grouping>
          <PlainLabel>Or</PlainLabel>
        </Grouping>
        <Grouping>
          <Link to='/create-your-own'  onClick={()=>toggleMobileMenu()}><SideMenuMobileItem>Create Your Own Set</SideMenuMobileItem></Link>
        </Grouping>
        </>
         : null }
    </>
  )
}