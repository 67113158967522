import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useCookies } from 'react-cookie';
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { useForm } from 'react-hook-form';
import { Transition } from 'react-transition-group';
import { graphql, useStaticQuery } from 'gatsby';
import Modal from '../Modal/Modal';
import HtmlParser from '../HtmlParser/HtmlParser';
import { TextField } from '../Forms/Forms';
import EmailLabel from './EmailLabel';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';

const FormWrapper = styled.form`
  position: relative;
  border-bottom: 1px solid #383B23;
`

const FormWrapperInner = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  opacity: ${props => props.disable ? '0' : '1'};
  transition: opacity .2s ease;
`


const SubmitButton = styled.button`
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
`

const Prompt = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: ${props => props.theme === 'light' ? '#F2EADE': '#383B23'};
  ${fontstack.default}
  ${type('m-body')}
  ${media.large`
    ${type('body')}
  `}
`

const Copy = styled.div`
  color:#383B23;
  ${fontstack.default}
  ${type('m-body')}
  ${media.large`
    ${type('body')}
  `}
`


const EmailPrompt = () => {
  const [cookies, setCookie] = useCookies(['emailprompt']);
  const [ toggle, setToggle ] = useState(false);
  const [ promptActive, setPromptActive ] = useState(false);
  const { register, handleSubmit, reset } = useForm();
  const mailchimpMsg = useRef();

  const {
   email: { data }
  } = useStaticQuery(graphql`
    query {
      email: prismicHomepage {
        data {
          email_cookie_expiry
          email_cta_label
          email_enabled
          email_input_placeholder
          email_title
          email_popup_delay
          email_copy {
            html
          }
        }
      }
    }
  `);
  const emailSettings = data;
  const { 
    email_cookie_expiry,
    email_enabled,
    email_title,
    email_copy,
    email_input_placeholder,
    email_cta_label,
    email_popup_delay
  } = emailSettings;


  useEffect(()=> {
    if(!cookies.emailprompt && email_enabled) {
      setTimeout(()=>{
        setToggle(true);
        setCookie('emailprompt', true, {
          maxAge: email_cookie_expiry*24*60*60
        })
      }, email_popup_delay || 5000)
    }
  },[]);

  const onSubmit = (data) => {
    addToMailchimp(data.email) // listFields are optional if you are only capturing the email address.
    .then(data => {
      firePrompt('We look forward to nourishing you.')
    }).catch(() => {
      firePrompt('Error.')
    });
  }

  const firePrompt = (msg) => {
    mailchimpMsg.current = msg;
    setPromptActive(true);

    setTimeout(() => {
      setPromptActive(false);
      reset();
      setToggle(false);

    }, 4000);

}

  return (
    <Transition in={toggle && email_enabled} timeout={{
      enter: 0,
      exit: 200,
     }}>
       {state => { 
         if(state !== 'exited') {
          return (
            <Modal title={email_title} state={state} onClose={()=>{setToggle(false)}}>
              { email_copy ? <Copy><HtmlParser>{email_copy.html}</HtmlParser></Copy> : null }
              <FormWrapper onSubmit={handleSubmit(onSubmit)}>
                <FormWrapperInner disable={promptActive}>    
                  <TextField type="text" placeholder={email_input_placeholder} {...register('email',{required: true, pattern: /^\S+@\S+$/i})} />
                  <SubmitButton type="submit">
                    <EmailLabel>{email_cta_label}</EmailLabel>
                  </SubmitButton>
                </FormWrapperInner>
                {promptActive ? <Prompt theme='dark'>
                    <p>{mailchimpMsg.current}</p>
                  </Prompt> : null }
              </FormWrapper>
            </Modal>
            )
         }
       }}
     </Transition>
  )
}

export default EmailPrompt