import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';
import { StoreContext } from "../../context/store-context";
import Navigation from '../Navigation/Navigation';
import NavigationMobile from '../Navigation/NavigationMobile';
import SideMenuMobile from '../SideMenu/SideMenuMobile';
import PlpNavigation from '../PlpNavigation/PlpNavigation';

const Nav = styled.div`
  position: ${props => props.position};
  transform: ${props => props.offset ? `translate(0,calc(${props.offset}px * -1))` : 'translate(0px,0px)'};
  ${({animatable}) => animatable && `
    transition: transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  `}
  width: 100%;
  z-index: 3;

  ${({mobile}) => mobile && `
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    min-height: 100%;
    overflow-y: auto;
  `}
`

const NavMobileBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 80px;
  width: 100%;
  background: #F2EADE;
  z-index: 0;

`

const NavSpacer = styled.div`
  position: relative;
  height: ${props => props.height ? `${props.height}px` : '80px'};
  width: 100%;
  background: #F2EADE;
  z-index: 1;
`

const PlpNavWrapper = styled.div` 
  position: relative;
  width: 100%;
`

const Header = ({plp, navBorder}) => {
  const { width, toggleMobileMenu, mobileMenuOpen, cartOpen, didJustAddToCart } = React.useContext(StoreContext);
  const cachedLastScrollPosition = useRef(0);
  const [ NavPosition, setNavPosition ] = useState('absolute');
  const [ NavYOffset, setNavYOffset ] = useState('0');
  const [ Animatable, setAnimatable ] = useState(false);
  const [isDesktop,setDesktop] = useState();

  useEffect(()=>{
    setDesktop(width >= 1080 ? true : false);

    if(width >= 1080 && mobileMenuOpen) {
      toggleMobileMenu();
    }

    const handleNavBasedOnScrollPosition = () => {
      const headHeight = 80;
      const position = window.scrollY;
      const scrollDirection = position - cachedLastScrollPosition.current;

      if(position > headHeight && NavPosition === 'absolute') {
        if(cartOpen && width < 1080) {
          setNavYOffset(0);
        } else {
          setNavYOffset(headHeight);
        }
        
        setNavPosition('fixed');
        setTimeout(()=>{
          setAnimatable(true);
        }, 300);
        
      } else if(position === 0) {
        setNavPosition('absolute');
        setNavYOffset(0);
        setAnimatable(false);
      }
  
      if(NavPosition === 'fixed' && position > headHeight && scrollDirection < 0) {
        setNavYOffset(0);
        
      } else if(NavPosition === 'fixed' && position > headHeight && scrollDirection > 0 && !cartOpen) {
        setNavYOffset(headHeight);
      } 
  
      cachedLastScrollPosition.current = position;
    }

    const slideOutNav = () => {
      
      if(NavPosition === 'fixed' && !cartOpen) {
        setNavYOffset(0);
      }
    }
    
    if(mobileMenuOpen) {
      document.removeEventListener('scroll', handleNavBasedOnScrollPosition)
    } else {
      document.addEventListener('scroll', handleNavBasedOnScrollPosition , {passive: true})
    }

    if(didJustAddToCart) {
      slideOutNav();
    }
    
    return () => document.removeEventListener('scroll', handleNavBasedOnScrollPosition);

  },[NavPosition, width, mobileMenuOpen, cartOpen, didJustAddToCart]);


  let spacerHeight;

  if(width >= 1080) {
    spacerHeight = 80;
    if(plp) {
      spacerHeight += 80;
    }
  } else {
    spacerHeight = 80;
    if(plp) {
      spacerHeight += 50;
    }
  }

  return (
    <>
      <Nav position={NavPosition} offset={NavYOffset} animatable={Animatable} mobile={mobileMenuOpen}>
        { isDesktop ? <Navigation border={navBorder}/> : <><NavigationMobile  border={navBorder} /><NavMobileBg/></> }
        { plp ? <PlpNavWrapper position={NavPosition} offset={NavYOffset} animatable={Animatable}><PlpNavigation active={plp}/></PlpNavWrapper> : null}
        
        <Transition in={mobileMenuOpen} timeout={{
          enter: 0,
          exit: 200,
        }}>
          {state => {
            if(state !== 'exited') {
              return <SideMenuMobile state={state}/>
            }
          }}
        </Transition>
      </Nav>
      <NavSpacer plp={plp} height={spacerHeight}/>
    </>
  )
}

export default Header;