import React from 'react';
import styled, { keyframes } from 'styled-components';

const ActionWrapper = styled.div` 
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.background ? props.background : props.theme === 'light' ? '#383B23': '#F3EFD7'};
  opacity: ${props => props.isLoading ? '1' : '0'};
  pointer-events: none;
  transition: opacity .2s ease;
`

const threeCircleLoader = keyframes`
  40% {
   opacity: 0;
  }
  100% {
   opacity: 1;
  }
`

const LoadingAnimate = styled.div` 
  position: relative;
  
  &,
  &:before,
  &:after {
    border-radius: 50%;
    width: 8px;
    height: 8px;
    background: ${props => props.theme === 'light' ? '#F3EFD7': '#383B23'};
    animation: ${threeCircleLoader} 1s infinite ease;
  }

  & {
    animation-delay: -0.15s;
  }

  &:before {
    left: -14px;
    animation-delay: -0.30s;
  }

  &:after {
    right: -14px;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
  }
`

const LoadingAnimation = ({isLoading, theme, background}) => {

  return (
    <ActionWrapper isLoading={isLoading} theme={theme} background={background}>
      <LoadingAnimate theme={theme}/>
    </ActionWrapper>
  )
}

export default LoadingAnimation;