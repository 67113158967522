import React from 'react';
import styled from 'styled-components';
import { media } from '../../utils/mediaQuery';

const Wrapper = styled.div`
  position: relative;
  ${({topShift}) => topShift && `
    margin-top: -20px;
    padding-top: 20px;
  `}

  ${({bottomShift}) => bottomShift && `
    margin-bottom: -20px;
    padding-bottom: 20px;
  `}

  ${({top}) => top && `
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding-top: 20px;
  `}

  ${({bottom}) => bottom && `
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-bottom: 20px;
  `}

  ${({spacer}) => spacer && `
      height: 80px;
  `}


  ${media.large`
    ${({topShift}) => topShift && `
      margin-top: -40px;
      padding-top: 40px;
    `}

    ${({bottomShift}) => bottomShift && `
      margin-bottom: -40px;
      padding-bottom: 40px;
    `}

    ${({top}) => top && `
      border-top-left-radius: 40px;
      border-top-right-radius: 40px;
      padding-top: 40px;
    `}

    ${({bottom}) => bottom && `
      border-bottom-left-radius: 40px;
      border-bottom-right-radius: 40px;
      padding-bottom: 40px;
    `}

    ${({spacer}) => spacer && `
      height: 100px;
    `}
  `}
  

  ${({topSquared}) => topSquared && `
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  `}

  ${({bottomSquared}) => bottomSquared && `
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `}

  ${({nopad}) => nopad && `
    padding: 0;
  `}

  background: ${props => props.background || '#fff'};
  z-index: ${props => props.zIndex || '0'};
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
`

const Bracket = (props) => {
  const { children, top, bottom, background, zIndex, topShift, bottomShift, topSquared, bottomSquared, nopad, spacer, ...rest } = props;

  return (
    <Wrapper 
      nopad={nopad}
      top={top} 
      bottom={bottom} 
      background={background} 
      zIndex={zIndex} 
      topShift={topShift} 
      bottomShift={bottomShift}
      topSquared={topSquared}
      bottomSquared={bottomSquared}
      spacer={spacer} {...rest}>
      {children}
    </Wrapper>
  )
}

export default Bracket;