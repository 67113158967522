import React from 'react';
import styled from 'styled-components';
import FooterFull from '../Footer/FooterFull';

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 0;
`

const Spacer = styled.div` 
  position: relative;
  height: 650px;
  width: 100%;
`

const Relative = styled.div`
  position: relative;
`

const BottomFooterWrapper = styled.div`
  position: relative;
`

const NewsletterWrapper = styled.div`
  position: absolute;
  width: 100%;
  z-index: 0;
  top: -230%;
`

const Footer = () => {

  return (
    <>
      <FooterFull />
    </>
  )
}

export default Footer;