import React from 'react';
import styled from 'styled-components';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';

const PTag = styled.p`
  ${fontstack.default}
  ${type('m-body')}
  margin: 0 0 20px; 

  ${media.large`
    ${type('body')}
  `}

  &:last-child{
      margin-bottom: 0;
  }
`

const AnchorTag = styled.a`
  ${fontstack.default}
  ${type('m-body')}
  text-decoration: underline;
  color: #000000;
  opacity: .5;

  ${media.large`
    ${type('body')}
  `}
`

const StrongTag = styled.strong` 
  font-weight: 500;
`

const EmTag = styled.em` 
  ${fontstack.secondary}
  font-weight: 300;
  letter-spacing: 0;
  font-style: italic;
`

const H3Tag = styled.h3`
  ${fontstack.default}
  ${type('m-body')}
  font-weight: 500;

  margin: 0 0 20px; 

  ${media.large`
    ${type('body')}
    font-weight: 500;
  `}

  &:last-child{
      margin-bottom: 0;
  }
`

const LITag = styled.li`
  ${fontstack.default}
  ${type('m-body')}
  margin: 0 0 20px; 
  position: relative;
  display: flex;

  ${media.large`
    ${type('body')}
  `}

  &:before {
    width: 30px;
  }
  
  & > span {
    flex: 1;
  }

  &:last-of-type{
      margin-bottom: 0;
  }
`

const UlTag = styled.ul`
  ${fontstack.default}
  ${type('m-body')}
  margin: 0 0 20px; 
  list-style: none;
  padding: 0;

  ${media.large`
    ${type('body')}
  `}

  & > li:before {
    content: '';
    width: 8px;
    height: 8px;
    background: #383B23;
    border-radius: 50%;
    margin: 4px 22px 0 0;
  }

  &:last-child{
      margin-bottom: 0;
  }
`

const OlTag = styled.ol`
  ${fontstack.default}
  ${type('m-body')}
  margin: 0 0 20px; 
  list-style: none;
  counter-reset: li;
  padding: 0;

  ${media.large`
    ${type('body')}
  `}

  & > li {
    counter-increment: li;
  }

  & > li:before {
    content: counter(li, lower-alpha) ". ";
  }

  &:last-child{
      margin-bottom: 0;
  }
`

const SupTag = styled.sup` 
    top: -0.2em;
`

const Paragraph = props => {
    const { children } = props;
    return (
        <PTag {...props}>{children}</PTag>
    )
}

const Strong = props => {
    const { children } = props;
    return (
        <StrongTag {...props}>{children}</StrongTag>
    )
}

const Em = props => {
    const { children } = props;
    return (
        <EmTag {...props}>{children}</EmTag>
    )
}

const Anchor = props => {
  const { children } = props;
  return (
      <AnchorTag {...props}>{children}</AnchorTag>
  )
}

const H3 = props => {
  const { children } = props;
  return (
      <H3Tag {...props}>{children}</H3Tag>
  )
}

const LI = props => {
  const { children } = props;
  return (
      <LITag {...props}><span>{children}</span></LITag>
  )
}

const OrderedList = props => {
  const { children } = props;
  return (
      <OlTag {...props}>{children}</OlTag>
  )
}

const UnorderedList = props => {
  const { children } = props;
  return (
      <UlTag {...props}>{children}</UlTag>
  )
}

const Sup = props => {
  const { children } = props;
  return <SupTag {...props}>{children}</SupTag>
}


export { 
    Paragraph,
    Strong,
    Em,
    Anchor,
    H3,
    OrderedList,
    UnorderedList,
    LI,
    Sup
 };